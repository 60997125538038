import Phaser, { GameObjects, Scene } from 'phaser'
import {sceneEvents} from "../../events/EventsCenter";

var keyM

export default class MusikButton extends Phaser.Scene {
  private thrill!: Phaser.Sound.BaseSound
  private backgroundmusic!: Phaser.Sound.BaseSound
  private buttonMusik!: Phaser.GameObjects.GameObject
  private roterStrich!: Phaser.GameObjects.Image

  private pausedSceneKey!: string;

  constructor() {
    super('musikButton');
  }

  init(data) {
    // Initialisierung des Szenen-Keys
    this.pausedSceneKey = data.pausedSceneKey;
  }

  create() {
    //Musik anschalten, wenn in vorherigem Level Musik an war
    var flag = localStorage.getItem('flag');
    if (flag == "true") {
        this.backgroundmusic = this.sound.get('backgroundmusic')
        this.sound.stopAll()
        this.thrill = this.sound.add('backgroundmusic', {volume: 0.5, loop: true})
        this.thrill.play()
    }

    //Tasten belegen für Musiksteuerung
    if(this.input?.keyboard){
        keyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
    }
    
    //Menü-Button Musik hinzufügen
    this.buttonMusik = this.add.circle(35, 40, 25, 0x888888).setScrollFactor(0)
    this.buttonMusik.setInteractive()
    this.add.image(33,40,'musiknote').setScale(0.35).setScrollFactor(0,0);
    this.roterStrich = this.add.image(36,40,'strichRot').setScale(0.40).setScrollFactor(0,0);

  }

     update(time: number, delta: number) {

        //Musik Button Steuerung
        var flag = localStorage.getItem('flag');

        this.buttonMusik.on('pointerdown', () => {
            if (flag == "true") {
                this.sound.stopAll() //Musik ausschalten
                localStorage.setItem('flag', "false");
            } else if (flag == "false") {//Musik anschalten
                this.backgroundmusic = this.sound.get('backgroundmusic')
                this.sound.stopAll()
                this.thrill = this.sound.add('backgroundmusic', {volume: 0.5, loop: true})
                this.thrill.play()
                localStorage.setItem('flag', "true");
            }
        });

        //Musik ausschalten über Keyboard
        if (Phaser.Input.Keyboard.JustDown(keyM)) {
            if (flag == "true") {
                this.sound.stopAll() //Musik ausschalten
                localStorage.setItem('flag', "false");
            } else if (flag == "false") {//Musik anschalten
                this.backgroundmusic = this.sound.get('backgroundmusic')
                this.sound.stopAll()
                this.thrill = this.sound.add('backgroundmusic', {volume: 0.5, loop: true})
                this.thrill.play()
                localStorage.setItem('flag', "true");
            }
        }

        if (flag == "true") { //Musik ausschalten
            this.roterStrich.setVisible(false)
        } else if (flag == "false") {//Musik anschalten
            this.roterStrich.setVisible(true)
        }

    }
}


