import Phaser from 'phaser'

const createOfficerAnimations = (anims: Phaser.Animations.AnimationManager) => {
	// Animationen des Wachmanns generieren
	anims.create({
		key: 'officer-idle-down',
		frames: [{ key: 'officer', frame: 'run-down-2.png' }]
	})
	anims.create({
		key: 'officer-idle-up',
		frames: [{ key: 'officer', frame: 'run-up-2.png' }]
	})
	anims.create({
		key: 'officer-idle-down',
		frames: [{ key: 'officer', frame: 'run-side-2.png' }]
	})
	anims.create({
		key: 'officer-run-down',
		frames: anims.generateFrameNames('officer', { start: 1, end: 4, prefix: 'run-down-', suffix: '.png' }),
		repeat: -1,
		frameRate: 10
	})
	anims.create({
		key: 'officer-run-up',
		frames: anims.generateFrameNames('officer', { start: 1, end: 4, prefix: 'run-up-', suffix: '.png' }),
		repeat: -1,
		frameRate: 10
	})
	anims.create({
		key: 'officer-run-side',
		frames: anims.generateFrameNames('officer', { start: 1, end: 4, prefix: 'run-side-', suffix: '.png' }),
		repeat: -1,
		frameRate: 10
	})
}

export {
	createOfficerAnimations
}
