import Phaser from "phaser";


export default class Preloader extends Phaser.Scene {
    preload() {
        //Progress Bar
        var progressBar = this.add.graphics();
        var progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(240, 270, 320, 50);

        var width = this.cameras.main.width;
        var height = this.cameras.main.height;
        progressBar.x = width / 9;
        progressBar.y = height / 2 - 300;
        progressBox.x = width / 9;
        progressBox.y = height / 2 - 300;
        var loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                color: '#ffffff'
            }
        });
        loadingText.setOrigin(0.5, 0.5);

        var percentText = this.make.text({
            x: width / 2,
            y: height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                color: '#ffffff'
            }
        });
        percentText.setOrigin(0.5, 0.5);

        var assetText = this.make.text({
            x: width / 2,
            y: height / 2 + 50,
            text: '',
            style: {
                font: '18px monospace',
                color: '#ffffff'
            }
        });
        assetText.setOrigin(0.5, 0.5);
        this.load.on('progress', function (value) {
            percentText.setText(parseInt(String(value * 100)) + '%');
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(250, 280, 300 * value, 30);
        });

        //Anzeige der einzelnen Assets, die geladen werden
        //this.load.on('fileprogress', function (file) {
        //    assetText.setText('Loading asset: ' + file.key);
        //});

        this.load.on('complete', function () {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
            assetText.destroy();
        });

        // Tiles vorladen
        this.load.tilemapTiledJSON('map1', 'assets/map/map1.json')
        this.load.tilemapTiledJSON('map2', 'assets/map/map2-fix.json')
        this.load.tilemapTiledJSON('map3', 'assets/map/map3.json')
        this.load.tilemapTiledJSON('map4', 'assets/map/map4-fix.json')
        this.load.tilemapTiledJSON('map5', 'assets/map/map5-fix_2.json')
        this.load.tilemapTiledJSON('map6', 'assets/map/map6-fix.json')
        this.load.tilemapTiledJSON('map7', 'assets/map/map7.json')
        this.load.tilemapTiledJSON('map8', 'assets/map/map8.json')
        this.load.tilemapTiledJSON('map9', 'assets/map/map9.json')
        this.load.image('tiles1', 'assets/map/tilesets/build_atlas.png')
        this.load.image('tiles2', 'assets/map/tilesets/Building.png')
        this.load.image('tiles3', 'assets/map/tilesets/Cars_final.png')
        this.load.image('tiles4', 'assets/map/tilesets/city_outside.png')
        this.load.image('tiles5', 'assets/map/tilesets/inside.png')
        this.load.image('tiles6', 'assets/map/tilesets/Interior-Furniture.png')
        this.load.image('tiles7', 'assets/map/tilesets/Interior-Walls-Beige.png')
        this.load.image('tiles8', 'assets/map/tilesets/Laserfence.png')
        this.load.image('tiles9', 'assets/map/tilesets/Objects.png')
        this.load.image('tiles10', 'assets/map/tilesets/Street.png')
        this.load.image('tiles11', 'assets/map/tilesets/terrain_atlas.png')
        this.load.image('tiles12', 'assets/map/tilesets/tileset_town_multi_v002.png')
        this.load.image('tiles13', 'assets/map/tilesets/treetop.png')
        this.load.image('tiles14', 'assets/map/tilesets/woodland_indoor.png')
        this.load.image('tiles15', 'assets/map/tilesets/Room1Tile.png')
        this.load.image('tiles16', 'assets/map/tilesets/pinturas-atlas.png')
        this.load.atlas('thief', 'assets/character/thief.png', 'assets/character/thief.json')
        this.load.atlas('thief_male', 'assets/character/thief_male.png', 'assets/character/thief_male.json')
        this.load.atlas('thief_non', 'assets/character/thief_non.png', 'assets/character/thief_non.json')

        this.load.image('nonbinary_strength', 'assets/character/thiefstrengthnonbinary.png')
        this.load.atlas('officer', 'assets/enemies/officer.png', 'assets/enemies/officer.json')
        this.load.image('rechenzentrum', 'assets/map/tilesets/rechenzentrum.png')//Bild von Rechenzentrum laden

        this.load.image('background', 'assets/logo/BackgroundQuestion.webp')
        this.load.image('creditsbackground', 'assets/logo/creditsbackground.jpg');
        this.load.image('walkietalkie', 'assets/partner/WalkieTalkie.png')
        this.load.image('partner', 'assets/partner/Partner.png')

        this.load.image('start1', 'assets/start/1.png')
        this.load.image('start2', 'assets/start/2.png')
        this.load.image('start3', 'assets/start/3.png')
        this.load.image('start4', 'assets/start/4.png')
        this.load.image('start5', 'assets/start/5.png')
        this.load.image('ball', 'assets/questions/balls.png')

        this.load.image('right', 'assets/symbols/checkmark.png')
        this.load.image('wrong', 'assets/symbols/cross.png')
        this.load.image('musiknote', 'assets/symbols/musiknote.png')
        this.load.image('strichRot', 'assets/symbols/strichRot.png')
        this.load.image('mapImage', 'assets/symbols/mapImage.png')
        this.load.image('doorButton', 'assets/symbols/doorButton.png')
        this.load.image('runButton', 'assets/symbols/runButton.png')
        this.load.image('pause', 'assets/symbols/pause.png')
        
        //Karten laden für Menübutton
        this.load.image('Karte1', 'assets/karten/Karte1.png')
        this.load.image('Karte2', 'assets/karten/Karte2.png')
        this.load.image('Karte3', 'assets/karten/Karte3.png')
        this.load.image('Karte4', 'assets/karten/Karte4.png')
        this.load.image('Karte5', 'assets/karten/Karte5.png')
        this.load.image('Karte6', 'assets/karten/Karte6.png')
        this.load.image('Karte7', 'assets/karten/Karte7.png')
        this.load.image('Karte8', 'assets/karten/Karte8.png')
        this.load.image('Karte9', 'assets/karten/Karte9.png')

        //Texteingabe laden
        this.load.html('form', 'form.html')

        this.load.audio('backgroundmusic', 'assets/sound/Backgroundmusic.mp3')
        this.load.audio('rightAnswer', 'assets/sound/RightAnswer.wav')
        this.load.audio('wrongAnswer', 'assets/sound/WrongAnswer.mp3')
        this.load.audio('caught', 'assets/sound/GotCaught.wav')
        this.load.audio('tension', 'assets/sound/Tension.mp3')
        this.load.audio('thrill', 'assets/sound/thrill.mp3')
        this.load.audio('question', 'assets/sound/question.mp3')
        this.load.spritesheet('numbers', 'assets/start/numbers.png', {frameWidth: 2038, frameHeight: 1156});

        //Laden für Highscore
        this.load.json('highscores', './scores/scorewerte.json');

        //Laden der questions
        this.load.json('jsonquestion', './fragen/questionpool.json');
        this.load.xml('xmlquestion', './fragen/newquestions.xml')
    }


    create() {
        // Hintergrundmusik
        var background = this.sound.add('backgroundmusic', {
            loop: true,
            volume: 0.5
        })
        background.play()
        localStorage.setItem('notice_count_1', "right");
        localStorage.setItem('notice_count_2', "right");
        localStorage.setItem('flag', 'true');
        localStorage.setItem('pause', 'true');
        localStorage.setItem('score', '0');

        localStorage.setItem('tutorial1', 'false');

        this.scene.start('start')

        
    }






}
