import Phaser from 'phaser';

const createThiefAnimations = (anims: Phaser.Animations.AnimationManager) => {
     const selectedGender = localStorage.getItem('selectedCharacterGender') || 'male';

    let characterKey = 'thief'; 
    if (selectedGender === 'male') {
        characterKey = 'thief_male';
    } else if (selectedGender === 'nonbinary') {
        characterKey = 'thief_non';
    }

    // Animationen des Charakters generieren
    anims.create({
        key: `${characterKey}-idle-down`,
        frames: [{ key: characterKey, frame: 'run-down-2.png' }]
    });

    anims.create({
        key: `${characterKey}-idle-up`,
        frames: [{ key: characterKey, frame: 'run-up-2.png' }]
    });

    anims.create({
        key: `${characterKey}-idle-side`,
        frames: [{ key: characterKey, frame: 'run-side-2.png' }]
    });

    anims.create({
        key: `${characterKey}-idle-side-left`,
        frames: [{ key: characterKey, frame: 'run-side-left-2.png' }]
    });

    anims.create({
        key: `${characterKey}-run-down`,
        frames: anims.generateFrameNames(characterKey, { start: 1, end: 3, prefix: 'run-down-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    });

    anims.create({
        key: `${characterKey}-run-up`,
        frames: anims.generateFrameNames(characterKey, { start: 1, end: 3, prefix: 'run-up-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    });

    anims.create({
        key: `${characterKey}-run-side`,
        frames: anims.generateFrameNames(characterKey, { start: 1, end: 3, prefix: 'run-side-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    });

    anims.create({
        key: `${characterKey}-run-side-left`,
        frames: anims.generateFrameNames(characterKey, { start: 1, end: 3, prefix: 'run-side-left-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    });
};

export {
    createThiefAnimations
};
