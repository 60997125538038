import Phaser, { GameObjects } from 'phaser'
import '../../characters/Thief'
import Thief from "../../characters/Thief"
import {createThiefAnimations} from "../../animations/ThiefAnimation"
import {sceneEvents} from "../../events/EventsCenter"
//import {Questions} from "../../assets/classes/Questions"
import {Hints, hints} from "../../assets/classes/Hints"
import {Karten, karten } from "../../assets/classes/Karten"
import Officer from "../../enemies/Officer"
import {createOfficerAnimations} from "../../animations/EnemyAnimation"
import QuestionLoaderLine from '../../assets/classes/QuestionLoaderLine'

var keyM

export default class Level8 extends Phaser.Scene {
    // Variablen belegen
    private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
    private thief!: Thief
    private officers!: Phaser.Physics.Arcade.Group
    private hintNum!: number
    private karte: Karten = new Karten(8)
    private karten: Karte[] = this.karte.getKarten()
    private kartenNum!: number

    private questionText!: string;
    private options!: string[];
    private explanation!: string;
    private randomQuestion: any;
    private rightAnswer: any;
    private questionId!: number;

    constructor() {
        // Variablen vorbelegen
        super('level8')
        //this.question.done = true
        this.hintNum = 8
        this.kartenNum = 1
    }

    create() {
        this.cameras.main.fadeIn(500)

        // Animationen erzeugen
        createThiefAnimations(this.anims)
        createOfficerAnimations(this.anims)


        const map = this.make.tilemap({key: 'map8'})

        // Tilesets hinzufügen
        const tileset7 = map.addTilesetImage('build_atlas', 'tiles1')
        const tileset8 = map.addTilesetImage('Building', 'tiles2')
        const tileset1 = map.addTilesetImage('Cars_final', 'tiles3')
        const tileset6 = map.addTilesetImage('city_outside', 'tiles4')
        const tileset11 = map.addTilesetImage('inside', 'tiles5')
        const tileset12 = map.addTilesetImage('Interior-Furniture', 'tiles6')
        const tileset10 = map.addTilesetImage('Interior-Walls-Beige', 'tiles7')
        const tileset14 = map.addTilesetImage('Laserfence', 'tiles8')
        const tileset9 = map.addTilesetImage('Objects', 'tiles9')
        const tileset2 = map.addTilesetImage('Street', 'tiles10')
        const tileset5 = map.addTilesetImage('terrain_atlas', 'tiles11')
        const tileset4 = map.addTilesetImage('tileset_town_multi_v002', 'tiles12')
        const tileset3 = map.addTilesetImage('treetop', 'tiles13')
        const tileset13 = map.addTilesetImage('woodland_indoor', 'tiles14')

        // Tilesets zu Layer hinzufügen
        //const allLayers = [tileset1, tileset2, tileset3, tileset4, tileset5, tileset6, tileset7, tileset8, tileset9, tileset10, tileset11, tileset12, tileset13, tileset14]
        const allLayers: Array<Phaser.Tilemaps.Tileset>  = [];//[tileset1, tileset2, tileset3, tileset4, tileset5, tileset6, tileset7, tileset8, tileset9, tileset10, tileset11, tileset12, tileset13, tileset14]
        if(tileset1)allLayers.push(tileset1);
        if(tileset2)allLayers.push(tileset2);
        if(tileset3)allLayers.push(tileset3);
        if(tileset4)allLayers.push(tileset4);
        if(tileset5)allLayers.push(tileset5);
        if(tileset6)allLayers.push(tileset6);
        if(tileset7)allLayers.push(tileset7);
        if(tileset8)allLayers.push(tileset8);
        if(tileset9)allLayers.push(tileset9);
        if(tileset10)allLayers.push(tileset10);
        if(tileset11)allLayers.push(tileset11);
        if(tileset12)allLayers.push(tileset12);
        if(tileset13)allLayers.push(tileset13);
        if(tileset14)allLayers.push(tileset14);
        // Gebäude, Mauern und Objekte zu Layern hinzufügen
        map.createLayer(0, allLayers)
        const gebaeude = map.createLayer(1, allLayers)
        const gebaeudeMauer = map.createLayer(2, allLayers)
        const objekte = map.createLayer(3, allLayers)


        // Thief erzeugen
        this.thief = this.add.thief(2000, -750, 'thief')
        if(this.thief?.body){
            this.thief.body.offset.y = 16
        }

        //Tasten belegen für Musiksteuerung
        if(this.input?.keyboard){
            keyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
        }
        // Kollission
        objekte?.setCollisionByExclusion([-1])
        gebaeudeMauer?.setCollisionByExclusion([-1])
        gebaeude?.setCollisionByExclusion([-1])

        //QuestionLoader
        const questionLoader = new QuestionLoaderLine();

        questionLoader.loadQuestion(8).then(questionData => {
          this.questionText = questionData.questionText;
          this.options = questionData.options;
          this.randomQuestion = questionData;
          this.rightAnswer = questionData.rightAnswer;
          this.explanation = questionData.explanation;
          this.questionId = questionData.questionId;
    
          const hints = questionData.hints;
          console.log("quesetionSate", questionData)
        });


        this.cameras.main.startFollow(this.thief, true)

        // Officers erzeugen
        this.officers = this.physics.add.group({
            classType: Officer,
            createCallback: (go) => {
                const offGo = go as Officer
                if(offGo?.body){
                    offGo.body.onCollide = true
                }
            }
        })
        let officer = this.officers.get(1800, -1050, 'officer')
        officer.setSize(30, 32)
        officer.body.offset.y = 16

        // Rectangle über Türe legen, damit Thief damit colliden kann
        var door = this.add.rectangle(1830, -1161, 150, 150)
        this.physics.add.existing(door, true)

        // Collider hinzufügen
        if(objekte) this.physics.add.collider(this.thief, objekte)
        if(gebaeudeMauer) this.physics.add.collider(this.thief, gebaeudeMauer)
        if(gebaeude) this.physics.add.collider(this.thief, gebaeude)
        if(objekte) this.physics.add.collider(this.officers, objekte)
        if(gebaeudeMauer) this.physics.add.collider(this.officers, gebaeudeMauer)
        if(gebaeude)this.physics.add.collider(this.officers, gebaeude)
        this.physics.add.collider(this.thief, this.officers, this.handleThiefOfficerCollision, undefined, this)
        this.physics.add.collider(this.thief, door, this.handleThiefDoorCollision, undefined, this)

      //Steuerung
      this.scene.launch('joystickTouch', {
        thief: this.thief
    });

        //PausenButton erstellen
        this.scene.launch('pausenButton', {
            pausedSceneKey: this.scene.key
        })

        //MapButton erstellen
        this.scene.launch('mapButton')

        //MusikButton erstellen
        this.scene.launch('musikButton')

        // Ruft Stoppuhr auf
        this.scene.launch("stopwatchscene")

        // walkietalkie
        this.scene.launch('equipment')
            
        // Nächstes Level aufrufen
        this.events.once('resume', (sys, data) => {
            if (data.bool) {
                this.scene.stop()
                this.scene.run('level9')
            } else if (!data.bool) {
                this.hintNum = 8
                this.kartenNum = 0
                this.scene.stop()
                this.scene.start()
            }
        })
       // Hinweise anzeigen
       sceneEvents.on('getHint', () => {
        if (this.scene.isActive('karten')) {
            this.scene.setVisible(false, 'karten')
        }

        this.scene.launch('hints', {
            content: hints[this.hintNum].content
        })
    })

    //Karten anzeigen
    sceneEvents.on('getKarten', () => {
        if (this.scene.isActive('hints')) {
            this.scene.setVisible(false, 'hints')
            this.scene.launch('equipment')
        }

        this.scene.launch('karten', {
            content: this.karten[0].content
        })
    })
    }


    handleThiefDoorCollision(obj1: Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject, obj2: Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject) {
        // Stelle sicher, dass keine doppelten Events registriert sind
        sceneEvents.off('openQuestion'); // Alte Listener entfernen
    
        this.thief.setQuestion();
    
        // Öffne die Frage-Szene nur einmal
        sceneEvents.once('openQuestion', () => {
            this.scene.pause();
            this.cleanUpScenes();
            this.scene.launch('questionLineScene', {
                question: this.questionText,
                answer1: this.options[0],
                answer2: this.options[1],
                answer3: this.options[2],
                answer4: this.options[3],
                answer5: this.options[4],
                answer6: this.options[5],
                rightAnswer: this.rightAnswer,
                explanation: this.explanation,
                caller: 'level8'
            });
    
            // Überwache die Antwortprüfung
            sceneEvents.once('questionAnswered', (isCorrect) => {
                if (isCorrect) {
                    // Nach richtiger Antwort: Wechsel zu Level9
                    this.cleanUpScenes();
                    this.scene.start('level9');
                } else {
                    // Nach falscher Antwort: Szene fortsetzen
                    this.scene.resume();
                }
            });
        });
    }
    
    // Funktion für Kollisions-Handling mit Officer
    handleThiefOfficerCollision(obj1: Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject, obj2: Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject) {
        // Kollision zwischen Thief und Officer führt zum Game Over
        this.events.removeListener('resume'); // Alle Listener entfernen
        this.cleanUpScenes();
        this.scene.start('gameOver', { caller: 'level8' });
    }
    
    // Funktion, um Sub-Szenen sauber zu bereinigen
    cleanUpScenes() {
        this.scene.stop('mapButton');
        this.scene.stop('karten');
        this.scene.stop('joystickTouch');
        this.scene.stop('hints');
        this.scene.stop('equipment');
        this.scene.stop('musikButton');
        this.scene.stop('stopwatchscene');
        sceneEvents.off('getHint');
        sceneEvents.off('getKarten');
    }
    
}

