import Phaser, { GameObjects } from 'phaser';
import VirtualJoyStick from 'phaser3-rex-plugins/plugins/virtualjoystick';
import { sceneEvents } from "../events/EventsCenter";

declare global {
    namespace Phaser.GameObjects {
        interface GameObjectFactory {
            thief(x: number, y: number, texture: string, frame?: string | number): Thief;
        }
    }
}

export default class Thief extends Phaser.Physics.Arcade.Sprite {
    private _questionsSolved: number = 0;
    private activeQuestion: boolean = false;
    private activeDoor: boolean = false;
    private textureKey: string;
    private moveKeys: { [key: string]: Phaser.Input.Keyboard.Key } = {};
    //private collisionBoxGraphics: Phaser.GameObjects.Graphics; // Visualisierung der Kollisionsbox

    get questionsSolved() {
        return this._questionsSolved;
    }

    set questionsSolved(number) {
        this._questionsSolved = number;
    }

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
        const selectedGender = localStorage.getItem('selectedCharacterGender');
        const textureKey = selectedGender === 'male' ? 'thief_male' :
                           selectedGender === 'nonbinary' ? 'thief_non' : 'thief';

        super(scene, x, y, textureKey, frame);
        this.textureKey = textureKey;

        // Eigenschaften definieren
        this.scale = 0.8; // Figur kleiner machen
        this.width = 16;
        this.height = 16;

        this.anims.play(`${this.textureKey}-idle-down`);

        // Graphics-Objekt für die Kollisionsbox erstellen
        //this.collisionBoxGraphics = scene.add.graphics();
        //this.collisionBoxGraphics.lineStyle(2, 0xff0000, 1); // Rote Linie

        sceneEvents.on('solved-question', () => {
            this._questionsSolved++;
        });

        if (scene.input && scene.input.keyboard) {
            this.moveKeys['W'] = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
            this.moveKeys['A'] = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
            this.moveKeys['S'] = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);
            this.moveKeys['D'] = scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
        }
    }

    //private updateCollisionBox() {
      //  if (this.body) {
        //    this.collisionBoxGraphics.clear(); // Vorherige Box löschen
          //  this.collisionBoxGraphics.lineStyle(2, 0xff0000, 1); // Rote Linie

            // Box zeichnen unter Berücksichtigung des Offsets
            //this.collisionBoxGraphics.strokeRect(
            //     this.body.x, // X-Position
            //    this.body.y, // Y-Position
            //    this.body.width, // Breite
            //    this.body.height // Höhe
         //   );
       // }
 //   }

    setQuestion() {
        this.activeQuestion = true;
    }

    setDoor() {
        this.activeDoor = true;
    }

    update(
        cursors: Phaser.Types.Input.Keyboard.CursorKeys,
        joyStick: VirtualJoyStick,
        buttonRechts: Phaser.GameObjects.GameObject,
        buttonLinks: Phaser.GameObjects.GameObject
    ) {
        if (!cursors || !joyStick) {
            return;
        }
    
        if (Phaser.Input.Keyboard.JustDown(cursors.space!)) {
            if (this.activeQuestion) {
                sceneEvents.emit('openQuestion');
            } else if (this.activeDoor) {
                sceneEvents.emit('openDoor');
            }
        }
    
        buttonRechts.on('pointerdown', () => {
            if (this.activeQuestion) {
                sceneEvents.emit('openQuestion');
            } else if (this.activeDoor) {
                sceneEvents.emit('openDoor');
            }
        });
    
        let speed = cursors.shift?.isDown || localStorage.getItem('flag_run') === "true" ? 225 : 150;
    
        const moveLeft = cursors.left?.isDown || this.moveKeys['A'].isDown || joyStick.left;
        const moveRight = cursors.right?.isDown || this.moveKeys['D'].isDown || joyStick.right;
        const moveUp = cursors.up?.isDown || this.moveKeys['W'].isDown || joyStick.up;
        const moveDown = cursors.down?.isDown || this.moveKeys['S'].isDown || joyStick.down;
    
        if (moveLeft) {
            this.anims.play(`${this.textureKey}-run-side`, true);
            this.setVelocity(-speed, 0);
            this.setFlipX(true); // Flip für die linke Bewegung
    
            // Kollisionsbox korrekt positionieren
            if (this.body) {
                this.body.setSize(32, 32); // Breite und Höhe der Box
                this.body.setOffset(12, 16); // Offset: weiter nach unten verschieben
            }
        } else if (moveRight) {
            this.anims.play(`${this.textureKey}-run-side`, true);
            this.setVelocity(speed, 0);
            this.setFlipX(false); // Flip für die rechte Bewegung
    
            // Kollisionsbox korrekt positionieren
            if (this.body) {
                this.body.setSize(32, 32); // Breite und Höhe der Box
                this.body.setOffset(12, 16); // Offset: weiter nach unten verschieben
            }
        } else if (moveUp) {
            this.anims.play(`${this.textureKey}-run-up`, true);
            this.setVelocity(0, -speed);
    
            // Kollisionsbox bleibt gleich
            if (this.body) {
                this.body.setSize(32, 32);
                this.body.setOffset(12, 16);
            }
        } else if (moveDown) {
            this.anims.play(`${this.textureKey}-run-down`, true);
            this.setVelocity(0, speed);
    
            // Kollisionsbox bleibt gleich
            if (this.body) {
                this.body.setSize(32, 32);
                this.body.setOffset(12, 16);
            }
        } else {
            if (this.anims && this.anims.currentAnim) {
                const parts = this.anims.currentAnim.key.split('-');
                parts[1] = 'idle';
                this.anims.play(parts.join('-'));
                this.setVelocity(0, 0);
            }
    
            // Standard-Kollisionsbox setzen
            if (this.body) {
                this.body.setSize(32, 32); // Standardgröße
                this.body.setOffset(12, 16); // Standard-Offset
            }
        }
    
        if (moveLeft || moveRight || moveUp || moveDown) {
            this.activeQuestion = false;
            this.activeDoor = false;
        }
    
        // Kollisionsbox aktualisieren (für Visualisierung)
       // this.updateCollisionBox();
    }
}

Phaser.GameObjects.GameObjectFactory.register('thief', function (
    this: Phaser.GameObjects.GameObjectFactory,
    x: number,
    y: number,
    texture: string,
    frame?: string | number
) {
    const sprite = new Thief(this.scene, x, y, texture, frame);

    this.displayList.add(sprite);
    this.updateList.add(sprite);

    this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY);

    if (sprite.body) {
        sprite.body.setSize(32, 32); // Breite und Höhe der Kollisionsbox
        sprite.body.setOffset(12, 16); // Box weiter nach rechts (X) und nach oben (Y) verschieben
    }
    return sprite;
});

