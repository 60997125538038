import Phaser from 'phaser'
import GameOver from "./scenes/GameOver";

import Start from "./scenes/Levels/Start";
import Preloader from "./scenes/Preloader";
import CharacterSelection from './scenes/Leaderboard/CharacterSelection';
import Level1 from "./scenes/Levels/Level1";
import Level2 from "./scenes/Levels/Level2";
import Level3 from "./scenes/Levels/Level3";
import Level4 from "./scenes/Levels/Level4";
import Level5 from "./scenes/Levels/Level5";
import Level6 from "./scenes/Levels/Level6";
import Level7 from "./scenes/Levels/Level7";
import Level8 from "./scenes/Levels/Level8";
import Level9 from "./scenes/Levels/Level9";
import QuestionScene from "./scenes/question/QuestionScene";
import DragDropZone from "./scenes/question/DragDropZone";
import QuestionLineScene from "./scenes/question/QuestionLineScene";
import Warning from "./scenes/question/Warning";
import Notice from "./scenes/question/Notice";
import Equipment from "./scenes/partner/Equipment";
import Hints from "./scenes/partner/Hints";
import End from "./scenes/End";
import FinalEnd from "./scenes/FinalEnd";
import MapButton from './scenes/menu/MapButton';
import PausenButton from './scenes/menu/PausenButton';
import MusikButton from './scenes/menu/MusikButton';
import joystickTouch from './scenes/menu/joystickTouch';
import Karten from './scenes/menu/Karten';
import StopwatchScene from './scenes/menu/StopwatchScene';
import Highscore from './scenes/Leaderboard/Highscore';
import PlayerName from './scenes/Leaderboard/PlayerName';
import Tutorialscene from './scenes/Levels/Tutorialscene';
import tutorialtexts from './scenes/partner/tutorialtexts';
import CharacterPreviewFemale from './scenes/Leaderboard/CharacterPreviewFemale';
import CharacterPreviewMale from './scenes/Leaderboard/CharacterPreviewMale';
import CharacterPreviewNon from './scenes/Leaderboard/CharacterPreviewNon';
import CharacterSpecialQuestion from './scenes/Levels/CharacterSpecialQuestion';
import CreditsScene from './scenes/CredtisScene';
const config: Phaser.Types.Core.GameConfig = {
    // GameConfig belegen
    width: 1024,
    height: 576,
    type: Phaser.AUTO,
    physics: {
        default: 'arcade',
        arcade: {
            gravity: {x: 0, y:0},
            debug: false
        }
    },
    input :{
		activePointers:3,
	  },
    //Config für Texteingabe: PlayerName
    dom: {
        createContainer: true
    },
    // Szenen anmelden, welche verwendet werden sollen. Neue Levels müssen hier angemeldet werden
    scene: [Preloader,PlayerName, CreditsScene, CharacterSpecialQuestion, CharacterSelection, CharacterPreviewNon, CharacterPreviewFemale, CharacterPreviewMale, Start, Level1, Level2, Level3, Level4, Level5, Level6, Level7, Level8, Level9, GameOver, QuestionScene, DragDropZone, QuestionLineScene, Warning, Notice, Equipment, Hints, PausenButton, MapButton, Tutorialscene, tutorialtexts, MusikButton, joystickTouch, Karten, End, FinalEnd, StopwatchScene, Highscore],
    scale: {
        parent: 'game',
    },
}

export default new Phaser.Game(config)
