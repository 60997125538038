import Phaser from 'phaser'

var KeyEsc;
var KeySpace;

export default class tutorialtexts extends Phaser.Scene {
    // Variablen belegen
    private textContent
    private timeChar = 30
    private content!: string

    constructor() {
        super('tutorialtexts');
    }

    init(data) {
        this.content = data.content
    }

    create() {
        // Sprechblasen erzeugen
        this.createSpeechBubble(50, 260, 400, 100, this.content)

        // Tastaturkeys zum Schließen der Speechbubble belegen
        if(this.input?.keyboard) {
            KeyEsc = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ESC)
            KeySpace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        }

    }

    update() {
        // Bei ESC Sprechblase schließen, bei Leertaste Text komplett anzeigen
        if (Phaser.Input.Keyboard.JustDown(KeyEsc)) {
            // ESC
            this.scene.stop()
            this.scene.start('equipment')
        } else if (Phaser.Input.Keyboard.JustDown(KeySpace)) {
            // Leertaste
            this.time.removeAllEvents()
            this.textContent.text = this.content
        }


    }

    createSpeechBubble(x, y, width, height, quote) {
        // Sprechblase generieren
        var bubbleWidth = width;
        var bubbleHeight = height;
        var bubblePadding = 10;
        var arrowWidth = bubbleWidth / 24

        var bubble = this.add.graphics({x: x, y: y})
            .fillStyle(0xffffff, 1)
            .lineStyle(4, 0x565656, 1)
            .strokeRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16)
            .fillRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16)

        // Koordinaten berechnen
        var point1X = bubbleWidth
        var point1Y = Math.floor(bubbleHeight / 7)
        var point2X = bubbleWidth
        var point2Y = Math.floor((bubbleHeight / 7) * 4)
        var point3X = Math.floor(bubbleWidth + arrowWidth)
        var point3Y = Math.floor(bubbleHeight / 7 * 2)

        //  Ausfüllen
        bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y)
        bubble.lineStyle(2, 0x565656, 1)
        bubble.lineBetween(point2X, point2Y, point3X, point3Y)
        bubble.lineBetween(point1X, point1Y, point3X, point3Y)

        this.textContent = this.add.text(0, 0, '', {
            // Text hinzufügen
            fontFamily: 'Arial',
            fontSize: '20px',
            color: '#000000',
            align: 'left',
            wordWrap: {width: bubbleWidth - (bubblePadding * 2)}
        })

        this.textContent.setPosition(bubble.x + bubblePadding, bubble.y + bubblePadding)

        // Text in Sprechblase hinzufügen
        var textArray = quote.split('')
        for (let i = 0; i < textArray.length; i++) {
            this.time.addEvent({
                delay: this.timeChar * i,
                callback: () => {
                    this.textContent.text = this.textContent.text.concat(textArray[i])
                }
            })
        }

    }
}


