import Phaser from 'phaser';
import { createThiefAnimations } from "../../animations/ThiefAnimation";

export default class CharacterPreviewFemale extends Phaser.Scene {
    private backButton!: Phaser.GameObjects.Rectangle;
    private saveButton!: Phaser.GameObjects.Rectangle;

    constructor() {
        super('characterpreviewfemale');
    }

    create() {
        // Hintergrundfarbe setzen
        this.cameras.main.setBackgroundColor('#000000');

        // Dynamische Skalierung der Schriftgröße
        const fontSize = Math.min(this.scale.width, this.scale.height) / 18;

        // Nachricht an den Spieler (Charaktername)
        this.add.text(
            this.scale.width / 2,
            this.scale.height / 6,
            'Dein Spezialist: LunaShade',
            {
                fontSize: `${fontSize}px`,
                color: '#ffffff',
                align: 'center',
                wordWrap: { width: this.scale.width * 0.8, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Vorstellungstext
        const introTextY = this.scale.height / 6 + fontSize * 2.0;
        this.add.text(
            this.scale.width / 2,
            introTextY,
            'Ich bin eine Meisterin der Schatten, bin clever und unberechenbar. Mit Präzision und Agilität überrasche ich ihre Gegner aus dem Verborgenen.',
            {
                fontSize: `${fontSize * 0.6}px`,
                color: '#ffffff',
                align: 'center', // Text linksbündig
                wordWrap: { width: this.scale.width * 0.6, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Animationen erstellen
        createThiefAnimations(this.anims);

        // Balken für Eigenschaften
        const barsData = [
            { label: 'Selbstbewusstsein', value: 68 },
            { label: 'Kreativität', value: 95 },
            { label: 'Teamgeist', value: 70 }
        ];

        const barWidth = this.scale.width / 3;
        const barHeight = 30;
        const barX = this.scale.width / 3 + 100;
        let barY = introTextY + fontSize * 2;

        const labelPadding = 10;
        const barPadding = 70;

        barsData.forEach(data => {
            this.add.rectangle(barX, barY + 20, barWidth, barHeight, 0xD3D3D3)
                .setOrigin(0, 0)
                .setAlpha(0.5);

            this.add.text(barX, barY - labelPadding, data.label, {
                fontSize: `${fontSize * 0.6}px`,
                color: '#ffffff',
                align: 'left'
            });

            const progressBar = this.add.rectangle(barX, barY + 20, 0, barHeight, 0xFF69B4)
                .setOrigin(0, 0);

            this.tweens.add({
                targets: progressBar,
                width: barWidth * (data.value / 100),
                duration: 1000,
                ease: 'Power2',
            });

            barY += barPadding;
        });

        // Position the Thief (aligned with the last bar)
        const lastBarBottom = barY - barPadding + 20 + barHeight; // Unterkante des letzten Balkens
        const thiefScale = 3.5;
        const thiefHeight = 32 * thiefScale; // Höhe des Thief-Sprites (32 ist die Standardgröße)
        const thiefOffsetY = -20; // Negativer Offset, um den Thief leicht nach oben zu verschieben
        const thiefY = lastBarBottom - thiefHeight / 2 + thiefOffsetY; // Zusätzlicher Offset für die Feinanpassung

        const femaleKeys = ['thief-run-down', 'thief-run-side-left', 'thief-run-up', 'thief-run-side'];
        let femaleKeyIndex = 0;

        const femaleThief = this.add.sprite(this.scale.width / 3, thiefY, 'thief')
            .setScale(thiefScale)
            .play({
                key: femaleKeys[femaleKeyIndex],
                frameRate: 6
            });

        // Zeitgesteuerter Wechsel der Animationen
        this.time.addEvent({
            delay: 1000,
            loop: true,
            callback: () => {
                femaleKeyIndex = (femaleKeyIndex + 1) % femaleKeys.length;

                femaleThief.play({
                    key: femaleKeys[femaleKeyIndex],
                    frameRate: 6
                });
            },
        });

        // Buttons unten
        const buttonWidth = this.scale.width / 4.5;
        const buttonHeight = this.scale.height / 15;
        const padding = 20;

        this.backButton = this.add.rectangle(
            padding + buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            buttonWidth,
            buttonHeight,
            0x333333
        )
            .setInteractive()
            .setStrokeStyle(2, 0xffffff);

        this.add.text(
            padding + buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            'Zurück',
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        this.saveButton = this.add.rectangle(
            this.scale.width - padding - buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            buttonWidth,
            buttonHeight,
            0x333333
        )
            .setInteractive()
            .setStrokeStyle(2, 0xffffff);

        this.add.text(
            this.scale.width - padding - buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            'Speichern',
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Hover-Effekte für Buttons
        const hoverEffect = (button: Phaser.GameObjects.Rectangle) => {
            button.on('pointerover', () => button.setFillStyle(0x555555));
            button.on('pointerout', () => button.setFillStyle(0x333333));
        };

        hoverEffect(this.backButton);
        hoverEffect(this.saveButton);

        // Button-Interaktionen
        this.backButton.on('pointerdown', () => {
            this.scene.stop();
            this.scene.start('characterselection');
        });

        this.saveButton.on('pointerdown', () => {
            localStorage.setItem('selectedCharacterGender', 'female');
            this.scene.stop();
            this.scene.start('playername');
        });
    }
}
