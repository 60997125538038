import Phaser from 'phaser'
import 'regenerator-runtime'

export default class Highscore extends Phaser.Scene {

    constructor() {
        super('highscore');
    }

    preload() {
        // Lade die JSON-Daten für die Highscores
        this.load.json('highscores', './scores/read.php?game=social');
    }

    create() {
        // Rufe die Highscore-Daten aus der JSON-Datei ab
        const highscores = this.cache.json.get('highscores');
        const data = highscores.data || [];

        // Sortiere die Highscores nach den Punktzahlen in absteigender Reihenfolge
        data.sort((a, b) => b.score - a.score);
    
        // Highscore-Liste
        const playerName = localStorage.getItem('player'); 
        const playerScore = localStorage.getItem('minutes');
        
        // Zeige die Highscore-Tabelle auf dem Bildschirm an
        let y = 100;
        for (let i = 0; i < data.length; i++) {
            const record = data[i];
            const text = `${i + 1}. ${record.name}: ${record.score}`;
    
            this.add.text(100, y, text, { fontSize: '32px', fontStyle: 'bold', color: '#ffffff' });
            y += 50;
        }
    
        // Beispiel: Ein neuer Highscore wird eingereicht
        this.submitScore(playerName, playerScore);
    }
    
    submitScore(name, score) {
        const formData = new FormData();
        formData.append('game', 'social');
        formData.append('name', name);
        formData.append('score', score.toString());
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', './scores/update.php', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                // Erfolgreiche Antwort vom Server erhalten
                console.log(xhr.responseText);
            } else {
                // Fehler beim Senden der Anfrage
                console.error('Fehler beim Einreichen des Highscores.');
            }
        };
        xhr.onerror = () => {
            // Fehler beim Senden der Anfrage
            console.error('Fehler beim Einreichen des Highscores.');
        };
        xhr.send(formData);
    }
}
