import Phaser from 'phaser'

export default class GameOver extends Phaser.Scene {
    private caller!: string

    constructor() {
        super('gameOver');
    }

    // Scene initialisieren, die GameOver aufgerufen hat
    init(data) {
        this.caller = data.caller
    }

    create() {
        this.scene.stop('hints')
        this.scene.stop('equipment')
        var flag = localStorage.getItem('flag')
        if (flag == "true") {
            const caught = this.sound.add('caught', {volume: 0.1})
            caught.play()
        }

        // Text hinzufügen
        this.scene.stop('equipment')
        this.add.text(300, 300, 'Oh nein, du wurdest von einem der Wachmänner erwischt! \nPass das nächste Mal besser auf!', {
            fontSize: '14px'
        })

        const restartButton = this.add.text(450, 360, 'Nochmal versuchen', {color: '#00FF00'}).setInteractive()
        // Event zum neustarten aufrufen
        restartButton.on('pointerdown', this.restartLevel, this)
    }

    // Event um das Level neu zu starten
    restartLevel() {
        this.scene.stop()
        this.scene.start('equipment')
        this.scene.start(this.caller)
    }
}
