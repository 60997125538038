import Phaser, { GameObjects } from 'phaser'
import '../../characters/Thief'
import Thief from "../../characters/Thief"
import {createThiefAnimations} from "../../animations/ThiefAnimation"
import {sceneEvents} from "../../events/EventsCenter"
import {Questions} from "../../assets/classes/Questions"
import {Hints, hints} from "../../assets/classes/Hints"
import {Karten, karten } from "../../assets/classes/Karten"

export default class TutorialScene extends Phaser.Scene {
    // Variablen belegen
    private thief!: Thief
    private questions: Questions = new Questions(1)
    private question: Question = this.questions.randomizeQuestion()
    private hintNum!: number
    private hint: Hints = new Hints(1, this.question.id)
    private hints: Hint[] = this.hint.getHints()
    private karte: Karten = new Karten(1)
    private karten: Karte[] = this.karte.getKarten()
    private kartenNum!: number
    private buttonYes!: Phaser.GameObjects.GameObject;
    private buttonNo!: Phaser.GameObjects.GameObject;
    private cursors!: Phaser.Types.Input.Keyboard.CursorKeys

    constructor() {
        // Variablen vorbelegen
        super('tutorialscene')
        this.question.done = true
        this.hintNum = 0
        this.kartenNum = 0
    }
    

    create() {
        // Kameraführung
        this.cameras.main.fadeIn(500)

        // Animationen erzeugen
        createThiefAnimations(this.anims)

        const map = this.make.tilemap({key: 'map1'})

        // Tilesets hinzufügen
        const tileset7 = map.addTilesetImage('build_atlas', 'tiles1')
        const tileset8 = map.addTilesetImage('Building', 'tiles2')
        const tileset1 = map.addTilesetImage('Cars_final', 'tiles3')
        const tileset6 = map.addTilesetImage('city_outside', 'tiles4')
        const tileset11 = map.addTilesetImage('inside', 'tiles5')
        const tileset12 = map.addTilesetImage('Interior-Furniture', 'tiles6')
        const tileset10 = map.addTilesetImage('Interior-Walls-Beige', 'tiles7')
        const tileset14 = map.addTilesetImage('Laserfence', 'tiles8')
        const tileset9 = map.addTilesetImage('Objects', 'tiles9')
        const tileset2 = map.addTilesetImage('Street', 'tiles10')
        const tileset5 = map.addTilesetImage('terrain_atlas', 'tiles11')
        const tileset4 = map.addTilesetImage('tileset_town_multi_v002', 'tiles12')
        const tileset3 = map.addTilesetImage('treetop', 'tiles13')
        const tileset13 = map.addTilesetImage('woodland_indoor', 'tiles14')

        // Tilesets zu Layer hinzufügen
        //const allLayers = [tileset1, tileset2, tileset3, tileset4, tileset5, tileset6, tileset7, tileset8, tileset9, tileset10, tileset11, tileset12, tileset13, tileset14]
        const allLayers: Array<Phaser.Tilemaps.Tileset>  = [];//[tileset1, tileset2, tileset3, tileset4, tileset5, tileset6, tileset7, tileset8, tileset9, tileset10, tileset11, tileset12, tileset13, tileset14]
        if(tileset1)allLayers.push(tileset1);
        if(tileset2)allLayers.push(tileset2);
        if(tileset3)allLayers.push(tileset3);
        if(tileset4)allLayers.push(tileset4);
        if(tileset5)allLayers.push(tileset5);
        if(tileset6)allLayers.push(tileset6);
        if(tileset7)allLayers.push(tileset7);
        if(tileset8)allLayers.push(tileset8);
        if(tileset9)allLayers.push(tileset9);
        if(tileset10)allLayers.push(tileset10);
        if(tileset11)allLayers.push(tileset11);
        if(tileset12)allLayers.push(tileset12);
        if(tileset13)allLayers.push(tileset13);
        if(tileset14)allLayers.push(tileset14);
        // Gebäude, Mauern und Objekte zu Layern hinzufügen
        map.createLayer(0, allLayers)
        const gebaeude = map.createLayer(1, allLayers)
        const gebaeudeMauer = map.createLayer(2, allLayers)
        const objekte = map.createLayer(3, allLayers)

         // Thief erzeugen
         this.thief = this.add.thief(1750, 1700, 'thief')
         if(this.thief?.body){
            this.thief.body.offset.y = 16
        }
 
         // Kollission
         objekte?.setCollisionByExclusion([-1])
         gebaeudeMauer?.setCollisionByExclusion([-1])
         gebaeude?.setCollisionByExclusion([-1])
 
         this.cameras.main.startFollow(this.thief, true)

         // Rectangle über Türe legen, damit Thief damit colliden kann
         var door = this.add.rectangle(1820, 850, 200, 100)
         this.physics.add.existing(door, true)
 
         // Collider hinzufügen
         if(objekte) this.physics.add.collider(this.thief, objekte)
        if(gebaeudeMauer) this.physics.add.collider(this.thief, gebaeudeMauer)
        if(gebaeude) this.physics.add.collider(this.thief, gebaeude)
         this.physics.add.collider(this.thief, door, this.handleThiefDoorCollision, undefined, this)
        
        // Erzeuge die Buttons für die Tutorial-Auswahl
        this.buttonYes = this.add.rectangle(425, 150, 180, 70, 0xffffff).setScrollFactor(0);
        this.buttonYes.setInteractive();
        const textYes = this.add.text(425, 150, 'Ja', {
            fontSize: '20px',
            color: '#0a0a0a',
            align: 'center'
        }).setOrigin(0.5).setScrollFactor(0);

        this.buttonNo = this.add.rectangle(625, 150, 180, 70, 0xffffff).setScrollFactor(0);
        this.buttonNo.setInteractive();
        const textNo = this.add.text(625, 150, 'Nein', {
            fontSize: '20px',
            color: '#0a0a0a',
            align: 'center'
        }).setOrigin(0.5).setScrollFactor(0);


        this.buttonYes.on('pointerdown', () => {
            this.scene.stop('tutorialtexts');
            this.startTutorial();
            this.buttonNo.destroy(true)
            this.buttonYes.destroy(true)
            textNo.setVisible(false); 
            textYes.setVisible(false); 
        });

        this.buttonNo.on('pointerdown', () => {
            this.scene.stop('tutorialtexts')
            this.startLevelOne()
        })

        // Frage den Spieler nach dem Tutorial
        this.scene.launch('tutorialtexts', {
            content: 'Willkommen bei Social Theftwork! Wenn du das erste Mal spielst, empfehlen wir dir das Tutorial zu spielen. Möchtest du das Tutorial spielen?'
        })


    }

    // Starte das Tutorial
    startTutorial() {
        // Zeige dem Spieler Schritt 1 des Tutorials
        this.scene.launch('tutorialtexts', {
            content: 'Schritt 1: Bewege den Spieler mit den Pfeiltasten, W/A/S/D-Tasten oder dem Joystick. Mit der Shifttaste oder dem Sprintsymbol kannst du sprinten.'
        });
    
        // Steuerung
        this.scene.launch('joystickTouch', {
            thief: this.thief
        });
    
        let initialThiefPosition = { x: this.thief.x, y: this.thief.y }; // Ausgangsposition des thief
        let distanceToMove = 150; // Die gewünschte Entfernung, die der thief bewegen soll
    
        // Überprüfe die Position des thief kontinuierlich
        const positionCheckInterval = setInterval(() => {
            let deltaX = this.thief.x - initialThiefPosition.x; // Veränderung der horizontalen Position
            let deltaY = this.thief.y - initialThiefPosition.y; // Veränderung der vertikalen Position
            let distanceMoved = Math.sqrt(deltaX * deltaX + deltaY * deltaY); // Zurückgelegte Gesamtentfernung
    
            if (distanceMoved >= distanceToMove) {
                this.step1();
                clearInterval(positionCheckInterval); // Stoppe die Überprüfung, sobald step1 ausgeführt wurde
            }
        }, 100); // Überprüfe alle 100 Millisekunden (kann an deine Anforderungen angepasst werden)
    }
    
    
    // Starte Level eins
    startLevelOne() {
        this.scene.start('level1')
    }

    handleThiefDoorCollision() {
        // Thief - Tür Kollission handlen
        this.thief.setQuestion()
        sceneEvents.once('openQuestion', () => {
            this.scene.launch('tutorialtexts', {
                content: 'Du hast das Tutorial abgeschlossen! Level 1 startet jetzt!'
            })
            this.time.delayedCall(5000, () => {
                this.scene.stop()
                this.scene.stop('tutorialtexts')
                this.scene.stop('stopwatchscene')
                // Rufe das Reset-Ereignis auf, um die Stoppuhr zurückzusetzen
                sceneEvents.emit('resetStopwatch');
                this.scene.start('level1')
            })
        })
    }

    update(time: number, delta: number): void {

    }

    step1() {
        // Erkläre den Pausenbutton
        this.time.delayedCall(3000, () => {
            this.scene.launch('tutorialtexts', {
                content: 'Schritt 2: Drücke den Pausenbutton, um das Spiel anzuhalten.'
              });
        })
      
        // PausenButton klicken
        this.scene.launch('pausenButton', {
          pausedSceneKey: this.scene.key
        }).bringToTop('pausenButton');
            
        const checkInterval = setInterval(() => {
          if (this.scene.isPaused()) {
            this.step2();
            clearInterval(checkInterval); // Stoppe die Überprüfung, sobald step2 ausgeführt wurde
          }
        }, 100); // Überprüfe alle 100 Millisekunden (kann an deine Anforderungen angepasst werden)
      }
      


    step2(){
        // Überprüfe, ob der Spieler den MapButton verwendet
        const pause = localStorage.getItem('pause');
            // Erkläre den Pausenbutton
            this.time.delayedCall(3000, () => {
                this.scene.launch('tutorialtexts', {
                    content: 'Schritt 3: Drücke den MapButton, um den aktuellen Stand des Thiefs anzuzeigen.'
                })
            })


        //Map erläutern
        this.scene.launch('mapButton').bringToTop('mapButton')
        
        //Karten anzeigen
        sceneEvents.on('getKarten', () => {
            if (this.scene.isActive('hints')) {
                this.scene.setVisible(false, 'hints')
                this.scene.launch('equipment')
            }

            this.scene.launch('karten', {
                content: this.karten[0].content
            })
        })

        const checkInterval = setInterval(() => {
            if (this.scene.isActive('karten')) {
              this.step3();
              clearInterval(checkInterval);
            }
          }, 100);
    }

    step3(){
         // Überprüfe, ob der Spieler den Musikbutton verwendet
            // Erkläre den Pausenbutton
            this.time.delayedCall(3000, () => {
                this.scene.launch('tutorialtexts', {
                    content: 'Schritt 4: Drücke den Musikbutton, um die Musik zu starten oder zu stoppen.'
                })
            })


        //Musikbutton erläutern
        this.scene.launch('musikButton')

        const Interval = setInterval(() => {
            if (localStorage.getItem('flag') === 'false') {
              this.step4();
              clearInterval(Interval); // Stoppe die Überprüfung, sobald step3 ausgeführt wurde
            }
          }, 100); // Überprüfe alle 100 Millisekunden (kann an deine Anforderungen angepasst werden)

    }

    step4(){
        // Erkläre das Equipment
        this.time.delayedCall(3000, () => {
            this.scene.launch('tutorialtexts', {
                content: 'Schritt 5: Drücke das Equipment, um nützliche Tipps vom Agenten zu erhalten.'
            })
        })

        this.scene.launch('equipment').bringToTop('equipment')

        // Hinweise anzeigen
        sceneEvents.on('getHint', () => {
            if (this.scene.isActive('karten')) {
                this.scene.setVisible(false, 'karten')
            }

            this.scene.launch('hints', {
                content: 'Hier stehen die Tipps vom Agenten!'
            })
        })

        const checkInterval = setInterval(() => {
            if (this.scene.isActive('hints')) {
                this.time.delayedCall(3000, () => {
                    this.step5()
                })
              clearInterval(checkInterval);
            }
          }, 100);
        
    }

    step5(){
        // Überprüfe, ob der Spieler den Stoppuhr verstanden hat
        this.time.delayedCall(1000, () => {
            this.scene.launch('tutorialtexts', {
                content: 'Schritt 6: Durch die Stoppuhr (oben in der Mitte) siehst du wie lange du schon spielst.'
            })
        })

        //Stoppuhr erklären
        this.scene.launch("stopwatchscene")

        this.time.delayedCall(6000, () => {
            this.step6()
        })

    }

    step6(){
        // Erkläre wie der Spieler die Tür öffnet
            // Erkläre den Pausenbutton
            this.scene.launch('tutorialtexts', {
                content: 'Schritt 7: Gehe zur Tür und öffne sie mit der Leertaste oder dem Türsymbol (Tablet).'
            })
    }

}
