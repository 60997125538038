import Phaser, { GameObjects } from 'phaser';
import '../../characters/Thief';
import Thief from "../../characters/Thief";
import { createThiefAnimations } from "../../animations/ThiefAnimation";
import { sceneEvents } from "../../events/EventsCenter";
import { Hints, hints } from "../../assets/classes/Hints";
import { Karten, karten } from "../../assets/classes/Karten";
import QuestionLoaderDrag from '../../assets/classes/QuestionLoaderDrag';

var keyM;

export default class Level5 extends Phaser.Scene {
    private thief!: Thief;
    private hintNum!: number;
    private karte: Karten = new Karten(5);
    private karten: Karte[] = this.karte.getKarten();
    private kartenNum!: number;

    private questionText!: string;
    private options!: string[];
    private explanation!: string;
    private randomQuestion: any;
    private rightAnswer: any;
    private questionId!: number;

    constructor() {
        super('level5');
        this.hintNum = 5;
        this.kartenNum = 1;
    }

    create() {
        this.cameras.main.fadeIn(500);

        if (this.hintNum == 5) {
            this.scene.launch('hints', {
                content: hints[5].content
            });
        }

        createThiefAnimations(this.anims);

        const map = this.make.tilemap({ key: 'map5' });

        // Tilesets hinzufügen
        const tileset1 = map.addTilesetImage('Cars_final', 'tiles3');
        const tileset2 = map.addTilesetImage('Street', 'tiles10');
        const tileset3 = map.addTilesetImage('treetop', 'tiles13');
        const tileset4 = map.addTilesetImage('tileset_town_multi_v002', 'tiles12');
        const tileset5 = map.addTilesetImage('terrain_atlas', 'tiles11');
        const tileset6 = map.addTilesetImage('city_outside', 'tiles4');
        const tileset7 = map.addTilesetImage('build_atlas', 'tiles1');
        const tileset8 = map.addTilesetImage('Building', 'tiles2');
        const tileset9 = map.addTilesetImage('Objects', 'tiles9');
        const tileset10 = map.addTilesetImage('Interior-Walls-Beige', 'tiles7');
        const tileset11 = map.addTilesetImage('inside', 'tiles5');
        const tileset12 = map.addTilesetImage('Interior-Furniture', 'tiles6');
        const tileset13 = map.addTilesetImage('woodland_indoor', 'tiles14');
        const tileset14 = map.addTilesetImage('Laserfence', 'tiles8');

        // Nur gültige Tilesets ins Array aufnehmen
        const allLayers: Array<Phaser.Tilemaps.Tileset> = [
            tileset1, tileset2, tileset3, tileset4, tileset5,
            tileset6, tileset7, tileset8, tileset9, tileset10,
            tileset11, tileset12, tileset13, tileset14
        ].filter((tileset): tileset is Phaser.Tilemaps.Tileset => !!tileset);

        // Layer erstellen
        map.createLayer(0, allLayers);
        const gebaeude = map.createLayer(1, allLayers);
        const gebaeudeMauer = map.createLayer(2, allLayers);
        const objekte = map.createLayer(3, allLayers);

        // Thief erstellen
        this.thief = this.add.thief(2000, -750, 'thief');
        if (this.thief?.body) {
            this.thief.body.offset.y = 16;
        }

        // Kollision einstellen
        objekte?.setCollisionByExclusion([-1]);
        gebaeudeMauer?.setCollisionByExclusion([-1]);

        // QuestionLoader
        const questionLoader = new QuestionLoaderDrag();
        questionLoader.loadQuestion(5).then(questionData => {
            this.questionText = questionData.questionText;
            this.options = questionData.options;
            this.randomQuestion = questionData;
            this.rightAnswer = questionData.rightAnswer;
            this.explanation = questionData.explanation;
            this.questionId = questionData.questionId;
        });

        this.cameras.main.startFollow(this.thief, true);

        // Musiksteuerung
        if (this.input?.keyboard) {
            keyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M);
        }

        // Tür-Kollision hinzufügen
        const door = this.add.rectangle(1770, -1461, 150, 110);
        this.physics.add.existing(door, true);
        // Collider hinzufügen
        if(objekte) this.physics.add.collider(this.thief, objekte)
        if(gebaeudeMauer) this.physics.add.collider(this.thief, gebaeudeMauer)
        if(gebaeude) this.physics.add.collider(this.thief, gebaeude)
    
        this.physics.add.collider(this.thief, door, this.handleThiefDoorCollision, undefined, this);

        // Sub-Szenen starten
        this.scene.launch('joystickTouch', { thief: this.thief });
        this.scene.launch('pausenButton', { pausedSceneKey: this.scene.key });
        this.scene.launch('mapButton');
        this.scene.launch('musikButton');
        this.scene.launch('stopwatchscene');
        this.scene.launch('equipment');

        // Levelwechsel optimieren
        this.events.once('resume', (sys, data) => {
            if (data.bool) {
                this.cleanUpScenes();
                this.scene.start('level6');
            } else if (!data.bool) {
                this.hintNum = 5;
                this.kartenNum = 0;
                this.cleanUpScenes();
                this.scene.restart();
            }
        });

        // Hinweise und Karten anzeigen
        sceneEvents.on('getHint', () => {
            if (this.scene.isActive('karten')) {
                this.scene.setVisible(false, 'karten');
            }
            this.scene.launch('hints', {
                content: hints[this.hintNum].content
            });
        });

        sceneEvents.on('getKarten', () => {
            if (this.scene.isActive('hints')) {
                this.scene.setVisible(false, 'hints');
                this.scene.launch('equipment');
            }
            this.scene.launch('karten', {
                content: this.karten[0].content
            });
        });
    }

    handleThiefDoorCollision() {
        // Setze die Frage und öffne das Fragefenster
        this.thief.setQuestion();
    
        // Stelle sicher, dass keine doppelten Event-Listener registriert werden
        sceneEvents.off('openQuestion'); // Alte Listener entfernen
    
        sceneEvents.once('openQuestion', () => {
            this.scene.pause();
            this.cleanUpScenes();
            this.scene.launch('dragDropZone', {
                question: this.questionText,
                answer1: this.options[0],
                answer2: this.options[1],
                answer3: this.options[2],
                answer4: this.options[3],
                answer5: this.options[4],
                answer6: this.options[5],
                rightAnswer: this.rightAnswer,
                explanation: this.explanation,
                caller: 'level5'
            });
    
            // Überwache die Beantwortung der Frage
            sceneEvents.once('questionAnswered', (isCorrect) => {
                if (isCorrect) {
                    // Nach richtiger Antwort: Wechsle zu Level6
                    this.cleanUpScenes();
                    this.scene.start('level6');
                } else {
                    // Nach falscher Antwort: Szene fortsetzen
                    this.scene.resume();
                }
            });
        });
    }
    
    cleanUpScenes() {
        this.scene.stop('mapButton');
        this.scene.stop('karten');
        this.scene.stop('joystickTouch');
        //this.scene.stop('hints');
        //this.scene.stop('equipment');
        //this.scene.stop('musikButton');
        //this.scene.stop('stopwatchscene');
       // sceneEvents.off('getHint');
       // sceneEvents.off('getKarten');
    }
    
}
