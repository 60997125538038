import Phaser from 'phaser';
import { sceneEvents } from '../../events/EventsCenter';
import QuestionScene from '../question/QuestionScene';

export default class PausenButton extends Phaser.Scene {
  private EscButton!: Phaser.GameObjects.Rectangle & Phaser.GameObjects.Components.Visible;
  private pauseText!: Phaser.GameObjects.Text;
  private pauseButton!: Phaser.GameObjects.GameObject & Phaser.GameObjects.Components.Visible;
  private pausedSceneKey!: string;
  private caller!: string
  private pausenImage!: Phaser.GameObjects.Image

  constructor() {
    super('pausenButton');
  }

  init(data: { pausedSceneKey: string }) {
    this.pausedSceneKey = data.pausedSceneKey;
  }

  create() {
    this.createPauseButton();
    this.createEscButton();
  }

  private createPauseButton() {
    this.pauseButton = this.add.circle(625, 25, 25, 0x888888).setScrollFactor(0).setInteractive();
    this.pausenImage = this.add.image(625, 25, 'pause').setScale(0.35).setScrollFactor(0, 0);
    this.input.on('gameobjectdown', this.handlePauseClick, this);
  }

  private createEscButton() {
    const rectWidth = 200;
    const rectHeight = 70;
    this.EscButton = this.add.rectangle(this.scale.width / 2, this.scale.height / 2, rectWidth, rectHeight, 0xffffff)
      .setScrollFactor(0)
      .setVisible(false)
      .setInteractive();

    this.pauseText = this.add.text(this.EscButton.x, this.EscButton.y, 'Weiterspielen', {
      fontSize: '24px',
      color: '#000000',
    })
      .setOrigin(0.5)
      .setVisible(false);

    this.EscButton.on('pointerdown', this.handleResumeClick, this);
  }

  private handlePauseClick() {
    const pause = localStorage.getItem('pause');

    if (pause === 'true') {
      localStorage.setItem('pause', 'false');
      this.pausenImage.setVisible(true)
      this.scene.resume('stopwatchscene');
      this.scene.resume('mapButton');
      this.scene.resume('equipment');
      this.game.scene.resume(this.pausedSceneKey, { caller: 'pausenButton' });
      this.EscButton.input.enabled = false;
    } else {
      this.showEscButton();
      this.pausenImage.setVisible(false)
      localStorage.setItem('pause', 'true');
      this.scene.pause('stopwatchscene');
      if (this.scene.isActive('karten')) {
        this.scene.setVisible(false, 'karten')
        this.scene.bringToTop('stopwatchscene')
      }
      this.scene.pause('mapButton');
      if (this.scene.isActive('hints')) {
        this.scene.setVisible(false, 'hints')
        this.scene.launch('equipment')
      }
      this.scene.pause('equipment');
      this.game.scene.pause(this.pausedSceneKey, { caller: 'pausenButton' });
      this.EscButton.input.enabled = true;

      if (this.scene.isActive('questionScene') || this.scene.isActive('questionLineScene') || this.scene.isActive('DragDropZone')) {
        this.scene.setVisible(false, 'questionScene');
        this.scene.setVisible(false, 'questionLineScene');
        this.scene.setVisible(false, 'DragDropZone');
        //this.scene.get('questionScene').input.enabled = false;
        const qS = this.scene.get('questionScene');
        if (qS && qS.input) {
          qS.input.enabled = false;
        }
        //this.scene.get('questionLineScene').input.enabled = false;
        const qLS = this.scene.get('questionLineScene');
        if (qLS && qLS.input) {
          qLS.input.enabled = false;
        }
        //this.scene.get('DragDropZone').input.enabled = false;
        const DDZ = this.scene.get('DragDropZone');
        if (DDZ && DDZ.input) {
          DDZ.input.enabled = false;
        }
      }
    }
  }

  private handleResumeClick() {
    localStorage.setItem('pause', 'true');
    this.hideEscButton();
    this.scene.resume('stopwatchscene');
    if (this.scene.isActive('questionScene') || this.scene.isActive('questionLineScene') || this.scene.isActive('DragDropZone')) {
      this.scene.setVisible(true, 'questionScene');
      this.scene.setVisible(true, 'questionLineScene');
      this.scene.setVisible(true, 'DragDropZone');
      //this.scene.get('questionScene').input.enabled = true;
      const qS = this.scene.get('questionScene');
      if (qS && qS.input) {
        qS.input.enabled = true;
      }
      //this.scene.get('questionLineScene').input.enabled = true;
      const qLS = this.scene.get('questionLineScene');
      if (qLS && qLS.input) {
        qLS.input.enabled = true;
      }
      //this.scene.get('DragDropZone').input.enabled = true;
      const DDZ = this.scene.get('DragDropZone');
      if (DDZ && DDZ.input) {
        DDZ.input.enabled = true;
      }
    }

    this.scene.pause('equipment');
    this.game.scene.pause(this.pausedSceneKey, { bool: false, caller: 'pausenButton', pausebool: false });
    
    this.EscButton.input.enabled = true;

    this.hideQuestionScenes();
  }

  private showQuestionScenes() {
    ['questionScene', 'questionLineScene', 'DragDropZone'].forEach(sceneKey => {
      if (this.scene.isActive(sceneKey)) {
        this.scene.setVisible(true, sceneKey);
      }
    });
  }

  private hideQuestionScenes() {
    ['questionScene', 'questionLineScene', 'DragDropZone'].forEach(sceneKey => {
      if (this.scene.isActive(sceneKey)) {
        this.scene.setVisible(false, sceneKey);
      }
    });
  }

  private showEscButton() {
    this.EscButton.setVisible(true);
    this.pauseText.setVisible(true);
    this.pauseButton.setVisible(false);
  }

  private hideEscButton() {
    this.EscButton.setVisible(false);
    this.pauseText.setVisible(false);
    this.pauseButton.setVisible(true);
  }
}
