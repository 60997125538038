import Phaser from 'phaser';
import { createThiefAnimations } from "../../animations/ThiefAnimation";

export default class CharacterPreviewMale extends Phaser.Scene {
    private backButton!: Phaser.GameObjects.Rectangle;
    private saveButton!: Phaser.GameObjects.Rectangle;

    constructor() {
        super('characterpreviewmale');
    }

    create() {
        // Hintergrundfarbe setzen
        this.cameras.main.setBackgroundColor('#000000');

        // Dynamische Skalierung der Schriftgröße
        const fontSize = Math.min(this.scale.width, this.scale.height) / 18;

        // Nachricht an den Spieler
        this.add.text(
            this.scale.width / 2,
            this.scale.height / 6,
            'Dein Spezialist: IronVortex',
            {
                fontSize: `${fontSize}px`,
                color: '#ffffff',
                align: 'center',
                wordWrap: { width: this.scale.width * 0.8, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Vorstellungstext
        const introTextY = this.scale.height / 6 + fontSize * 2.0;
        this.add.text(
            this.scale.width / 2,
            introTextY,
            'Ich nutzte seine Umgebung meisterhaft und findet immer einen Weg, das Blatt zu meinen Gunsten zu wenden. Wo andere Hindernisse sehen, sehe ich Möglichkeiten.',
            {
                fontSize: `${fontSize * 0.6}px`,
                color: '#ffffff',
                align: 'center',
                wordWrap: { width: this.scale.width * 0.6, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Animationen für den Dieb erstellen
        createThiefAnimations(this.anims);

        // Balken für Eigenschaften 
        const barsData = [
            { label: 'Selbstbewusstsein', value: 90 },
            { label: 'Kreativität', value: 60 },
            { label: 'Teamgeist', value: 70 }
        ];

        const barWidth = this.scale.width / 3; // Balkenbreite basierend auf der Bildschirmbreite
        const barHeight = 30;
        const barX = this.scale.width / 3 + 100; // X-Position der Balken (rechts vom Dieb)
        let barY = introTextY + fontSize *2; // Start-Y-Position für die 1. Eigenschaft

        // Abstand zwischen Balken und Schrift
        const labelPadding = 10; // Label und Balken
        const barPadding = 70; // zwischen Balken

        barsData.forEach(data => {
                this.add.rectangle(barX, barY + 20, barWidth, barHeight, 0x808080)
                .setOrigin(0, 0)
                .setAlpha(0.5); // Hintergrundbalken halbtransparent

            // Eigenschaftsname (Label) hinzufügen
            this.add.text(barX, barY - labelPadding, data.label, {
                fontSize: `${fontSize * 0.6}px`, // Kleinere Schriftgröße (60%)
                color: '#ffffff',
                align: 'left'
            });

            // Farbigen Balken hinzufügen
            const progressBar = this.add.rectangle(barX, barY + 20, 0, barHeight, 0x1E90FF)
                .setOrigin(0, 0);

            // Animation für Balken
            this.tweens.add({
                targets: progressBar,
                width: barWidth * (data.value / 100), // Endbreite des Balkens
                duration: 1000, // Dauer der Animation in Millisekunden (hier 1 Sekunde)
                ease: 'Power2', // Beschleunigung/ Verzögerung der Animation
            });

            barY += barPadding; // Abstand zwischen den Balken und den Texten
        });

        const lastBarBottom = barY - barPadding + 20 + barHeight; // Unterkante des letzten Balkens
        const thiefScale = 3.5;
        const thiefHeight = 32 * thiefScale; // Höhe des Thief-Sprites (32 ist die Standardgröße)
        const thiefOffsetY = -20; // Negativer Offset, um den Thief leicht nach oben zu verschieben
        const thiefY = lastBarBottom - thiefHeight / 2 + thiefOffsetY; // Zusätzlicher Offset für die Feinanpassung


        // Positionen für den Dieb
        const middleY = this.scale.height / 2;

        // Männlicher Dieb (läuft)
        const maleKeys = ['thief_male-run-down', 'thief_male-run-side-left', 'thief_male-run-up', 'thief_male-run-side'];
        let maleKeyIndex = 0;

        const maleThief = this.add.sprite(this.scale.width / 3, thiefY, 'thief_male')
            .setScale(thiefScale)
            .play({
                key: maleKeys[maleKeyIndex], // Erste Animation abspielen
                frameRate: 6 // Setze die Geschwindigkeit sofort beim ersten Start
            });

        // Zeitgesteuerter Wechsel der Animationen für den männlichen Dieb
        this.time.addEvent({
            delay: 1000, // Zeit in Millisekunden zwischen Animationen
            loop: true, // Dauerschleife
            callback: () => {
                maleKeyIndex = (maleKeyIndex + 1) % maleKeys.length; // Nächster Key (zirkulär)
                maleThief.play({
                    key: maleKeys[maleKeyIndex], // Animation ändern
                    frameRate: 6 // Geschwindigkeit (frameRate) angeben
                });
            },
        });

        // Position der Buttons
        const buttonWidth = this.scale.width / 4.5;
        const buttonHeight = this.scale.height / 15;

        // Abstand vom Rand der Buttons
        const padding = 20;

        // Zurück-Button links unten, etwas weiter vom Rand
        this.backButton = this.add.rectangle(
            padding + buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            padding + buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            'Zurück', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Speichern-Button rechts unten, etwas weiter vom Rand
        this.saveButton = this.add.rectangle(
            this.scale.width - padding - buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            this.scale.width - padding - buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            'Speichern', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Hover-Effekte für Buttons
        const hoverEffect = (button: Phaser.GameObjects.Rectangle) => {
            button.on('pointerover', () => button.setFillStyle(0x555555));
            button.on('pointerout', () => button.setFillStyle(0x333333));
        };

        hoverEffect(this.backButton);
        hoverEffect(this.saveButton);

        // Button-Interaktionen
        this.backButton.on('pointerdown', () => {
            this.scene.stop();
            this.scene.start('characterselection'); 
        });

        this.saveButton.on('pointerdown', () => {
            localStorage.setItem('selectedCharacterGender', 'male');
            this.scene.stop();
            this.scene.start('playername');
        });
    }
}
