import Phaser from "phaser"

var KeySpace
var KeyEnter
var KeyM
var anstext

export default class Warning extends Phaser.Scene {
    // Variablen belegen
    private text!: string
    private headline!: string
    private erklarung!: string
    private caller!: string
    private answer!: boolean
    private rightAnswerSound: any
    private wrongAnswerSound: any
    private tension!: Phaser.Sound.BaseSound
    private backgroundmusic!: Phaser.Sound.BaseSound

    constructor() {
        super('warning')
    }

    // initialisieren der Warning Daten
    create(data) {
        this.scene.stop('hints')
        this.scene.stop('equipment')
        this.text = data.text
        this.caller = data.caller
        this.answer = data.answer
        this.rightAnswerSound = this.sound.add('rightAnswer', {volume: 0.1})
        this.wrongAnswerSound = this.sound.add('wrongAnswer', {volume: 0.1})

        //Sound für Beantwortung der Frage
        var flag = localStorage.getItem('flag');
        if (flag == 'true') {

            if (this.answer) {
                this.rightAnswerSound.play()
                this.backgroundmusic = this.sound.get('backgroundmusic')
                this.sound.stopAll()
                this.tension = this.sound.add('question', {volume: 0.1, loop: true})
                this.tension.play()
                
            } else {
                this.wrongAnswerSound.play()
                this.backgroundmusic = this.sound.get('backgroundmusic')
                this.sound.stopAll()
                this.tension = this.sound.add('thrill', {volume: 0.5, loop: true})
                this.tension.play()

            }
        }

        // Rechteck und Text für Warning erstellen
        const warning = this.add.rectangle(512, 288, 600, 300, 0xFFFAFA).setStrokeStyle(2, 0x000000)
        this.add.text(warning.getLeftCenter().x + 38, 232, this.text, {
            fontSize: '15px',
            color: '#296D0B',
            fontStyle: 'bold',
            align: 'center',
            wordWrap: {
                width: 500
            }
        })

        //Antworten data.header
        const headline = this.add.text(280, 164, data.header, {
            fontSize: '22px',
            fontStyle: 'bold',
            color: '#0xffff00',
            align: 'center'

        })
        /*
        if(this.answer){
            const erklareung = this.add.text(250, 250, 'Erklärung:', {
                fontSize: '17px',
                color: '#0xffff00',
                fontStyle: 'bold'
            })

        }*/ 

        // Text hinzufügen
        const text = this.add.text(500, 400, 'OK', {
            fontSize: '32px',
            color: '#000000',
            fontStyle: 'bold'
        }).setInteractive()

        // Event wenn der 'OK' Text bestätigt wird
        text.on('pointerdown', () => {
            const self = this;
            this.scene.stop();
            window.setTimeout( () => {
                this.scene.start('equipment');
                window.setTimeout( () => {
                    self.scene.resume(this.caller, {caller: 'warning'})
                }, 50);    
            },50);
            //this.scene.start('equipment')
            //this.scene.resume(this.caller, {caller: 'warning'})
        })

        if( this.input && this.input.keyboard) {
            // Tastaturkeys zum Schließen des Hinweis belegen
            KeySpace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE)
            KeyEnter = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER)

            //Tasten belegen für Musiksteuerung
            KeyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
        }
        else {
            console.log("Input or Keyboard do not exists");
        }
    }

    // Tastatureingaben abfangen, um Hinweis mit Space oder Enter wegdrücken zu können
    update() {
        if (Phaser.Input.Keyboard.JustDown(KeySpace) || Phaser.Input.Keyboard.JustDown(KeyEnter)) {
            const self = this;
            this.scene.stop();
            window.setTimeout( () => {
                this.scene.start('equipment');
                window.setTimeout( () => {
                    self.scene.resume(this.caller, {caller: 'warning'})
                }, 50);    
            },50);
            //this.scene.start('equipment')
            //this.scene.resume(this.caller, {caller: 'warning'})
        }
    }
}
