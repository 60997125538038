import Phaser from 'phaser';
import Thief from "../characters/Thief"

enum Direction {
    UP,
    DOWN,
    LEFT,
    RIGHT
}

const randomDirection = (exclude: Direction) => {
    // Officer läuft zufällig durch den Raum
    let newDirection = Phaser.Math.Between(0, 3)
    while (newDirection === exclude) {
        newDirection = Phaser.Math.Between(0, 3)
    }

    return newDirection
}

export default class Officer extends Phaser.Physics.Arcade.Sprite {
    private thief!: Thief

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame: string | number) {
        super(scene, x, y, texture, frame);
        scene.add.existing(this);
        scene.physics.add.existing(this);

        // Animationen hinzufügen
        scene.anims.create({
            key: 'officer-run-up',
            frames: scene.anims.generateFrameNumbers(texture, { start: 8, end: 11 }),
            frameRate: 10,
            repeat: -1
        });

        scene.anims.create({
            key: 'officer-run-down',
            frames: scene.anims.generateFrameNumbers(texture, { start: 0, end: 3 }),
            frameRate: 10,
            repeat: -1
        });

        scene.anims.create({
            key: 'officer-run-side',
            frames: scene.anims.generateFrameNumbers(texture, { start: 4, end: 7 }),
            frameRate: 10,
            repeat: -1
        });

        this.anims.play('officer-run-down');
    }

    setThief(thief: Thief) {
        this.thief = thief;
    }

    update() {
        console.log("officerUpdate");
        if (this.thief) {
            this.moveTowardsThief();
        }
    }

    body = this.body;

    private moveTowardsThief() {
        const distance = Phaser.Math.Distance.Between(this.x, this.y, this.thief.x, this.thief.y);
        console.log("Distance: ", distance);
        if (distance < 200) {
            console.log("OfficerRunNearDistance ");
            // Der Dieb ist in der Nähe, den Officer in Richtung des Diebs bewegen
            this.scene.physics.moveToObject(this, this.thief, 100);
            
            // Animationen während der Bewegung abspielen
            if (Math.abs(this.body.velocity.y) > Math.abs(this.body.velocity.x)) {
                if (this.body.velocity.y < 0) {
                    this.anims.play('officer-run-up', true);
                } else if (this.body.velocity.y > 0) {
                    this.anims.play('officer-run-down', true);
                }
            } else {
                if (this.body.velocity.x < 0) {
                    this.anims.play('officer-run-side', true);
                    this.scaleX = 1;
                    this.body.offset.x = 0;
                } else if (this.body.velocity.x > 0) {
                    this.anims.play('officer-run-side', true);
                    this.scaleX = -1;
                    this.body.offset.x = 32;
                }
            }

        } else {
            console.log("OfficerRun ");
            // Der Dieb ist nicht in der Nähe, den Officer in zufälliger Richtung bewegen
            if (!this.body.velocity.x && !this.body.velocity.y) {
                const newDirection = this.randomDirection();
                switch (newDirection) {
                    case Direction.UP:
                        this.setVelocity(0, -100);
                        this.anims.play('officer-run-up', true);
                        break;
                    case Direction.DOWN:
                        this.setVelocity(0, 100);
                        this.anims.play('officer-run-down', true);
                        break;
                    case Direction.LEFT:
                        this.setVelocity(-100, 0);
                        this.anims.play('officer-run-side', true);
                        this.scaleX = 1;
                        this.body.offset.x = 0;
                        break;
                    case Direction.RIGHT:
                        this.setVelocity(100, 0);
                        this.anims.play('officer-run-side', true);
                        this.scaleX = -1;
                        this.body.offset.x = 32;
                        break;
                }
            }
        }
    }

    private randomDirection(): Direction {
        const directions = [Direction.UP, Direction.DOWN, Direction.LEFT, Direction.RIGHT];
        const currentDirection = this.body.velocity.x !== 0 ? (this.body.velocity.x > 0 ? Direction.RIGHT : Direction.LEFT) :
            (this.body.velocity.y > 0 ? Direction.DOWN : Direction.UP);
        const availableDirections = directions.filter(dir => dir !== currentDirection);
        return Phaser.Utils.Array.GetRandom(availableDirections);
    }
}
