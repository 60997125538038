import Phaser from 'phaser';

export default class CreditsScene extends Phaser.Scene {
  private scrollSpeed: number = 50; // Geschwindigkeit des Scrollens (Pixel pro Sekunde)
  private creditsText!: Phaser.GameObjects.Text;
  private creditsEndY!: number; // Y-Position, ab der der Abspann beendet wird

  constructor() {
    super('credits');
  }

  create() {
    // Hintergrundbild einfügen
    const bg = this.add.image(0, 0, 'creditsbackground').setOrigin(0, 0);
    bg.setDisplaySize(this.scale.width, this.scale.height);


    const centerX = this.scale.width / 2;

    // Text für den Abspann erstellen
    const creditsContent = `
    🖥️ Entwickelt von:
    Mascha Hosp
    Sema Ünal
    Lena Gelewski

    🎵 Musik:
    Musik: Ross Bugden https://www.youtube.com/c/RossBugden

    🎨 Spritesheets:
    Female: drjamgo@hotmail.com, Benjamin K. Smith (BenCreating), bluecarrot16, TheraHedwig, Evert, MuffinElZangano, Durrani, Pierre Vigier (pvigier), ElizaWy, Matthew Krohn (makrohn), Johannes Sjölund (wulax), Stephen Challener (Redshrike), Thane Brimhall (pennomi), laetissima

    Male: drjamgo@hotmail.com, bluecarrot16, Benjamin K. Smith (BenCreating), Evert, Eliza Wyatt (ElizaWy), TheraHedwig, MuffinElZangano, Durrani, Johannes Sj?lund (wulax), Stephen Challener (Redshrike), Manuel Riecke (MrBeast), Johannes Sjölund (wulax), JaidynReiman, ElizaWy, Bluecarrot16, Nila122

    Nonbinary: drjamgo@hotmail.com, bluecarrot16, Benjamin K. Smith (BenCreating), Evert, Eliza Wyatt (ElizaWy), TheraHedwig, MuffinElZangano, Durrani, Johannes Sj?lund (wulax), Stephen Challener (Redshrike), ElizaWy, Johannes Sjölund (wulax), JaidynReiman, Nila122

    📷 Foto:
    Adrien Olichon https://www.pexels.com/de-de/foto/schwarze-sanddunen-2387793/

    💡 Engine:
    Phaser v3.87.0

    Vielen Dank für's Spielen!
    `;

    const fontSize = Math.min(this.scale.width, this.scale.height) / 20;

    // Abspanntext mittig und außerhalb des sichtbaren Bereichs platzieren
    this.creditsText = this.add.text(centerX, this.scale.height, creditsContent, {
      fontSize: `${fontSize * 0.8}px`,
      color: '#FFFFFF',
      align: 'center',
      wordWrap: { width: this.scale.width * 0.8, useAdvancedWrap: true },
    }).setOrigin(0.5, 0);

    // Endposition berechnen, an der der Abspann vollständig aus dem Bild ist
    this.creditsEndY = -this.creditsText.height;

    // Automatische Rückkehr zur Startseite am Ende des Abspanns
    this.time.delayedCall(
      (this.creditsText.height + this.scale.height) / this.scrollSpeed * 1000, 
      () => this.scene.start(window.location.href = 'index.html')
    );
  }

  update(time: number, delta: number) {
    // Abspanntext vertikal nach oben bewegen
    const moveAmount = (this.scrollSpeed * delta) / 1000; // Geschwindigkeit an Delta-Zeit anpassen
    this.creditsText.y -= moveAmount;

    // Debugging (optional): Prüfen, ob der Text den Endpunkt erreicht
    if (this.creditsText.y <= this.creditsEndY) {
      console.log('Credits haben das Ende erreicht');
    }
  }
}
