import Phaser from 'phaser'

export default class End extends Phaser.Scene {


    constructor() {
        super('end');
    }

    create() {
        //Stoppt die Zeit
        this.scene.get('stopwatchscene').events.emit('stopCountdown');
        this.scene.stop('stopwatchscene')
        this.scene.stop('equipment')
        this.scene.stop('mapButton')
        this.scene.stop('joystickTouch')
        this.scene.stop('pausenButton')

        var config = {
            key: 'explodeAnimation',
            frames: this.anims.generateFrameNumbers('numbers', {start: 0, end: 23, first: 23}),
            frameRate: 20,
            repeat: -1
        };


        this.anims.create(config);


        this.add.sprite(400, 300, 'numbers').play('explodeAnimation');

        this.time.delayedCall(3000, () => {
            this.scene.stop('end')
            this.scene.start('characterspecialquestion')
        })

        var flag = localStorage.getItem('flag');
        if (flag == "false") {
            this.sound.stopAll()
        }


    }
}


