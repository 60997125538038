import { Hints, hints } from "../../assets/classes/Hints";

interface Question {
  id: number;
  difficulty: number;
  question: string;
  answers: {
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    answer5: string;
    answer6: string;
  };
  rightAnswer: number[][];
  done: boolean;
}

class QuestionLoaderLine {
  private questionText!: string;
  private options!: string[];
  private explanation!: string;
  private randomQuestion: any;
  private rightAnswer: any;
  private questionId!: number;

  constructor() {
    this.questionText = '';
    this.options = [];
    this.randomQuestion = null;
    this.rightAnswer = 0;
    this.explanation = '';
    this.questionId = 0;
  }

  loadQuestion(level) {
    return fetch('./fragen/questionpoolLine.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Fehler beim Laden der JSON-Datei');
        }
        return response.json();
      })
      .then((jsonData: Question[]) => {
        console.log('Geladene JSON-Datei:', jsonData);

        const questions = jsonData;
        const level1Questions = questions.filter(question =>
          question.difficulty === level
        );

        const randomIndex = Phaser.Math.Between(0, level1Questions.length - 1);
        const randomQuestion = level1Questions[randomIndex];

        this.questionText = randomQuestion.question;
        this.options = Object.values(randomQuestion.answers);
        this.rightAnswer = randomQuestion.rightAnswer;
        this.explanation = randomQuestion.explanation;
        this.questionId = randomQuestion.id;

        console.log('Level-1-Frage:', this.questionText);
        console.log('Antworten:', this.options);
        console.log('Erklärung:', this.explanation);
        console.log('rightAnswerIndex:', this.rightAnswer);
        console.log('questionId:', this.questionId);

        const hint = new Hints(level, this.questionId);
        const hints = hint.getHints();

        return {
          questionText: this.questionText,
          options: this.options,
          rightAnswer: this.rightAnswer,
          explanation: this.explanation,
          questionId: this.questionId,
          hints: hints,
        };
      });
  }
}

export default QuestionLoaderLine;
