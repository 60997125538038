import Phaser, { GameObjects, Scene } from 'phaser'
import {sceneEvents} from "../../events/EventsCenter";
import VirtualJoyStick from 'phaser3-rex-plugins/plugins/virtualjoystick'
import Thief from "../../characters/Thief"

var keyM

export default class joystickTouch extends Phaser.Scene {
    private joyStick!: VirtualJoyStick
    private buttonRechts!: Phaser.GameObjects.Arc
    private buttonLinks!: Phaser.GameObjects.Arc
    private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
    private thief!: Thief

  constructor() {
    super('joystickTouch');
  }

  init(data) {
    // Initialisierung des Szenen-Keys
    this.thief = data.thief;
  }

  preload() {
    // Tilesets laden
    if (this.input?.keyboard) {
      this.cursors = this.input.keyboard.createCursorKeys()
    }
    }

  create() {
    //Button erzeugen
    this.buttonRechts = this.add.circle(950, 500, 50, 0x888888).setScrollFactor(0);
    var doorbutton = this.add.image(950,500, 'doorButton').setScrollFactor(0).setScale(0.15)
    this.buttonRechts.setInteractive();

    this.buttonLinks = this.add.circle(800, 500, 50, 0x888888).setScrollFactor(0);
    this.buttonLinks.setInteractive();

    var button_run = this.add.sprite(800,500,'runButton').setScale(0.15).setScrollFactor(0,0);
    button_run.setInteractive()
    button_run.on('pointerdown',function(pointer2){
        localStorage.setItem('flag_run', "true")
    })

    button_run.on('pointerout',function(pointer2){
        localStorage.setItem('flag_run', "false")
    })

    //Joystick erzeugen
    this.joyStick = new VirtualJoyStick(this, {
        x: 100,
        y: 500,
        radius: 50,
        base: this.add.circle(0, 0, 50, 0x888888),
        thumb: this.add.circle(0, 0, 25, 0xcccccc),
        dir: '4dir',
        // forceMin: 16,
        fixed: true,
        // enable: true
        });

    //Joystick erscheinen lassen, sofern Desktop oder Mobile
    if (this.sys.game.device.os.desktop) {
        this.joyStick.setVisible(false)
        this.buttonLinks.setVisible(false)
        this.buttonRechts.setVisible(false)
        button_run.setVisible(false)
        doorbutton.setVisible(false)
    }

  }

    update(time: number, delta: number) {
       if (this.thief) {
           this.thief.update(this.cursors, this.joyStick, this.buttonRechts, this.buttonLinks)
        }
    }
}


