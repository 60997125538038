import Phaser from 'phaser';
import { createThreeThiefsAnimations } from '../../animations/ThreeThiefsAnimation';

export default class CharacterSpecialQuestion extends Phaser.Scene {
  private saveButton!: Phaser.GameObjects.Rectangle;

  constructor() {
    super('characterspecialquestion');
  }

  create() {
    const fontSize = Math.min(this.scale.width, this.scale.height) / 18;

    // Nachricht an den Spieler
    this.add.text(
      this.scale.width / 2,
      this.scale.height / 4,
      'Spezialist-Frage!',
      {
        fontSize: `${fontSize}px`,
        color: '#ffffff',
        align: 'center',
        wordWrap: { width: this.scale.width * 0.8, useAdvancedWrap: true },
      }
    ).setOrigin(0.5);

    const selectedGender = localStorage.getItem('selectedCharacterGender') || 'female';
    const { thiefSprite, characterStrength, question, answers, rightAnswer, explanation } =
      this.getCharacterDetails(selectedGender);

    createThreeThiefsAnimations(this.anims, thiefSprite);

    const middleY = this.scale.height / 2;
    const thiefScale = 3;

    // Thief-Sprite
    const thief = this.add.sprite(this.scale.width / 3, middleY, thiefSprite)
      .setScale(thiefScale)
      .play({ key: `${thiefSprite}-idle-down` });

    // Info-Text
    const infoText = `Dein Spezialist verfügt über außergewöhnliche ${characterStrength}-Fähigkeiten. Nutze diese Stärke, um die nächste Frage richtig zu beantworten und 10 Sekunden von deiner Zeit abzuziehen.`;
    const textX = this.scale.width / 3 + thief.width / 2 + 50;
    this.add.text(textX, middleY, infoText, {
      fontSize: `${fontSize * 0.6}px`,
      color: '#ffffff',
      align: 'left',
      wordWrap: { width: this.scale.width * 0.5, useAdvancedWrap: true },
    }).setOrigin(0, 0.5);

    // Zeit-Text
    const scoreTime = localStorage.getItem('stoppedTime') || '0';
    this.add.text(textX, middleY + 80, `Deine aktuelle Spielzeit beträgt: ${scoreTime} Minuten!`, {
      fontSize: `${fontSize * 0.6}px`,
      color: '#ffffff',
      align: 'left',
      wordWrap: { width: this.scale.width * 0.5, useAdvancedWrap: true },
    }).setOrigin(0, 0.5);

    const buttonWidth = this.scale.width / 4.5;
    const buttonHeight = this.scale.height / 15;
    const padding = 20;

    // Challenge-Button
    this.saveButton = this.add.rectangle(
      this.scale.width / 2,
      this.scale.height - padding - buttonHeight / 2,
      buttonWidth,
      buttonHeight,
      0x333333
    ).setInteractive().setStrokeStyle(2, 0xffffff);

    this.add.text(
      this.scale.width / 2,
      this.scale.height - padding - buttonHeight / 2,
      'Challenge',
      {
        fontSize: `${fontSize * 0.8}px`,
        color: '#ffffff',
      }
    ).setOrigin(0.5);

    this.saveButton.on('pointerover', () => this.saveButton.setFillStyle(0x555555));
    this.saveButton.on('pointerout', () => this.saveButton.setFillStyle(0x333333));

    this.saveButton.on('pointerdown', () => {
      this.startQuestionScene();
    });
  }

  private getCharacterDetails(gender: string): {
    thiefSprite: string,
    characterStrength: string,
    question: string,
    answers: string[],
    rightAnswer: number,
    explanation: string
  } {
    if (gender === 'male') {
      return {
        thiefSprite: 'thief_male',
        characterStrength: 'Selbstbewusstsein',
        question: 'Dein Freund erzählt dir, dass jemand online versucht hat, persönliche Informationen von ihm zu bekommen. Er hat aber Angst, das einem Erwachsenen zu sagen. Was könntest du tun?',
        answers: ['Du ermutigst ihn, mit einem Erwachsenen zu sprechen, und bietest an, ihn zu begleiten.', 'Du sagst, dass er sich keine Sorgen machen soll, und vergisst das Thema.', 'Du erzählst sofort ohne seine Zustimmung alles dem Lehrer.'],
        rightAnswer: 1, //Achtung hier geht es nicht um den Index, da die Logik nach der Antwortnummer geht nicht nach Index
        explanation: 'Selbstbewusste Menschen können anderen Mut machen, Probleme offen anzugehen. Sie handeln verantwortungsvoll und unterstützen ihre Freunde, ohne sie zu übergehen.'
      };
    } else if (gender === 'female') {
      return {
        thiefSprite: 'thief',
        characterStrength: 'Kreativität',
        question: 'Deine Klasse soll eine Geschichte über einen Tag ohne Internet schreiben. Wie könntest du eine spannende Geschichte beginnen?',
        answers: ['Der Tag ohne Internet war langweilig und nichts Interessantes passierte.', 'Ich hatte keine Lust, die Aufgabe zu machen, also schrieb ich nichts.', 'Es war ein ganz normaler Morgen, bis plötzlich alle Geräte nicht mehr funktionierten.'],
        rightAnswer: 3,
        explanation: 'Ein kreativer Einstieg macht die Geschichte interessant und spannend. Langweilige oder gar keine Antworten zeigen, dass keine neuen Ideen eingebracht wurden.'
      };
    } else if (gender === 'nonbinary') {
      return {
        thiefSprite: 'thief_non',
        characterStrength: 'Teamgeist',
        question: 'Deine Klasse arbeitet an einem Projekt, bei dem ein Plakat über sicheres Verhalten im Internet gestaltet wird. Ein Klassenkamerad hat Probleme, seinen Teil rechtzeitig fertigzustellen. Was solltest du tun?',
        answers: ['Du machst seinen Teil schnell selbst, damit das Projekt rechtzeitig fertig wird.', 'Du hilfst ihm, seine Ideen zu verbessern und gemeinsam fertigzustellen.', 'Du sagst dem Lehrer, dass er nicht gearbeitet hat, und machst weiter mit deinem eigenen Teil.'],
        rightAnswer: 2,
        explanation: 'Bei einem Projekt kommt es darauf an, dass alle zusammenarbeiten und sich gegenseitig unterstützen. So wird das Ergebnis besser, und jeder fühlt sich wertgeschätzt.'
      };
    } else {
      // Fallback für nicht definierte Charaktere
      return {
        thiefSprite: 'thief',
        characterStrength: 'Selbstbewusstsein',
        question: 'Welche Jahreszeit hat 31 Tage?',
        answers: ['Sommer', 'Winter', 'Frühling'],
        rightAnswer: 1, // Frage noch überarbeiten
        explanation: 'Der Sommer hat Monate mit 31 Tagen wie Juli.'
      };
    }
  }

  private startQuestionScene() {
    // Hole das Geschlecht des Charakters aus dem lokalen Speicher oder setze 'female' als Standard
    const selectedGender = localStorage.getItem('selectedCharacterGender') || 'female';

    // Hole die spezifischen Details für den Charakter basierend auf dem Geschlecht
    const { question, answers, rightAnswer, explanation } = this.getCharacterDetails(selectedGender);

    // Starte die QuestionScene mit den dynamischen Daten
    this.scene.launch('questionScene', {
      question: question,
      answer1: answers[0],
      answer2: answers[1],
      answer3: answers[2],
      rightAnswer: rightAnswer,
      explanation: explanation,
      caller: this.scene.key // Übergibt die aktuelle Szene als Caller
    });

    // Szene pausieren
    this.scene.pause();

    this.events.on('resume', (sys, data) => {
      if (data.bool && data.caller === 'questionScene') {
        const storedTimeStr = localStorage.getItem('stoppedTime');
        if (storedTimeStr) { //ist ein Zeitwert im localstorage vorhanden
          const [minutesStr, secondsStr] = storedTimeStr.split(':');  //Zeitwert in Min und Sek aufteilen
          let minutes = parseInt(minutesStr, 10); //Minuten als Zahl parsen
          let seconds = parseInt(secondsStr, 10); //Sekunden als Zahl pasen

          // 10 Sekunden von gespeicherten Sekunden abziehen
          seconds -= 10;

          //falls Sekunden dann negativ sind, Minuten anpassen und Sekunden korrigieren
          if (seconds < 0) {
            minutes -= 1; //eine Minute abziehen
            seconds += 60; //60Sekunden erhöhen (1min)
          }

          // Aktualisierte Zeit speichern - String im Fortmat "MM:SS"
          const newTimeStr = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
          localStorage.setItem('stoppedTime', newTimeStr);
          this.scene.stop()
          this.scene.start('finalend')
        } else {
          this.scene.stop()
          this.scene.start('finalend')
        }
      }else{
        this.scene.stop()
        this.scene.start('finalend')
      }})
  }
}
