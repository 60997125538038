import Phaser from 'phaser'

var KeyEsc;
var KeySpace;

export default class Karten extends Phaser.Scene {
    private EscButton!: Phaser.GameObjects.GameObject;
    content: any;

    constructor() {
        super('karten');
    }

    init(data) {
        this.content = data.content
    }

    create() {
        // Esc Button erzeugen
        this.EscButton = this.add.rectangle(525, 500, 180, 70, 0xffffff).setScrollFactor(0)
        this.EscButton.setInteractive()
        this.add.text(472, 490, 'Schließen', {
            fontSize: '20px',
            color: '#0a0a0a',
        })

        this.add.image(525,220, this.content)
            .setScale(0.3)

        // Tastaturkeys zum Schließen der Speechbubble belegen
        if(this.input?.keyboard) {
            KeyEsc = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ESC)
            KeySpace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        }

        this.scene.sendToBack('stopwatchscene')
    }

    update() {
        // Bei ESC Karten schließen
        if (Phaser.Input.Keyboard.JustDown(KeyEsc)) {
            // ESC
            this.scene.stop()
            this.scene.start('mapButton')
            this.scene.bringToTop('stopwatchscene')
        } 
        
        this.EscButton.on('pointerdown', () => {
            this.scene.stop()
            this.scene.bringToTop('stopwatchscene')
        });
    }
}


