import Phaser, { GameObjects } from "phaser";
 
var keyM
 
export default class Start extends Phaser.Scene {
    // Variablen belegen
    private graphCount: number = 0
    private graphNames = ['start1', 'start2', 'start3', 'start4', 'start5']
    private image
    private thrill!: Phaser.Sound.BaseSound
    private backgroundmusic!: Phaser.Sound.BaseSound
    private backwardEllipse!: GameObjects.Ellipse;
    private forwardEllipse!: GameObjects.Ellipse;
    private forwardGraphic!: GameObjects.Graphics;
    private backwardGraphic!: GameObjects.Graphics;
    private downloadButton;
 
    constructor() {
        super('start');
    }
 
    create() {
        // Anfangssequenz einbinden
        this.image = this.add.image(512, 288, this.graphNames[this.graphCount])
            .setScale(0.4)
        this.forwardEllipse = this.add.ellipse(974, 520, 50, 50, 0xffffff, 75)
            .setStrokeStyle(2, 0xffffff)
            .setInteractive()
            .setName('forward')
            .setDepth(10)
        this.forwardGraphic = this.add.graphics({x: 960, y: 515})
            .fillStyle(0x000000)
            .strokeRect(0, 0, 20, 10)
            .fillRect(0, 0, 20, 10)
            .setDepth(12)
            .fillTriangle(20, 15, 20, -5, 30, 5)
        this.backwardEllipse = this.add.ellipse(50, 520, 50, 50, 0xffffff, 75)
            .setStrokeStyle(2, 0xffffff)
            .setInteractive()
            .setName('backward')
            .setDepth(10)
 
 
 
        //Button zur Seite mit den Arbeitsmaterialien
        this.downloadButton = this.add.container(0, 0); //Container erstellen, um Button und Text gemeinsam zu verwalten
 
        const buttonWidth = this.scale.width / 4.5;
        const buttonHeight = this.scale.height / 15;
        const padding = 20;
 
        const buttonXX = padding + buttonWidth / 2;
        const buttonYY = this.scale.height - padding - buttonHeight / 2;
 
       
        const buttonBackground = this.add.rectangle(
           buttonXX,
           buttonYY,
           buttonWidth,
           buttonHeight,
           0x333333
        )
         .setInteractive()
         .setStrokeStyle(2, 0xffffff)
         .on('pointerover', () => buttonBackground.setFillStyle(0x555555))
         .on('pointerout', () => buttonBackground.setFillStyle(0x333333))
         .on('pointerdown', () => {
           window.location.href = 'https://social.dhbw-stuttgart.de/Arbeitsmaterialien/download.html';
         });
 
        //Text auf dem Button
        const buttonText = this.add.text(
           buttonXX,
           buttonYY,
            'Arbeitsmaterialien',
             {
               fontSize: '20px',
               color: '#ffffff',
               fontFamily: 'Arial',
             }
            ).setOrigin(0.5);
 
        // Button und Text zum Container hinzufügen
        this.downloadButton.add([buttonBackground, buttonText]);
 
        this.downloadButton.setDepth(10).setVisible(true);
 
 
       /* this.downloadButton = this.add.text(15, 525, 'Arbeitsmaterialien', {
            fontFamily: 'Arial',
            fontSize: '20px',
            color: '#ffffff'
            })
            .setInteractive()
            .setDepth(10)
            .on('pointerdown', () => {
            window.location.href = 'https://social.dhbw-stuttgart.de/Arbeitsmaterialien/download.html';
            });*/
 
 
 
        this.backwardGraphic = this.add.graphics({x: 45, y: 515})
            .fillStyle(0x000000)
            .strokeRect(0, 0, 20, 10)
            .fillRect(0, 0, 20, 10)
            .setDepth(12)
            .fillTriangle(0, 15, 0, -5, -10, 5)
 
        this.input.on('gameobjectdown', this.handleClick, this)
 
        //Tasten belegen für Musiksteuerung
        //if(this.input?.keyboard){
        //    keyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
        //}
    }
 
    // Bei Klick entsprechend vorwärts oder rückwärts navigieren
    handleClick(pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.GameObject) {
        this.image.destroy()
        if (gameObject.name == 'forward') {
            this.graphCount++
        } else {
            this.graphCount--
        }
        if (this.graphCount == 5) {
            this.scene.stop()
            this.scene.start('characterselection')
        } else if (this.graphCount == -1) {
            this.graphCount = 0
        }
        this.image = this.add.image(512, 288, this.graphNames[this.graphCount])
            .setScale(0.4)
    }
   
 
 
    update(time: number, delta: number) {
        //Sound-Taste gedrückt
        /*if (Phaser.Input.Keyboard.JustDown(keyM)) {
            var flag = localStorage.getItem('flag');
            console.log(flag)
            if (flag == "true") {
                this.sound.stopAll() //Musik ausschalten
                localStorage.setItem('flag', "false");
            } else if (flag == "false") { //Musik anschalten
                this.backgroundmusic = this.sound.get('backgroundmusic')
                this.sound.stopAll()
                this.thrill = this.sound.add('backgroundmusic', {volume: 0.5, loop: true})
                this.thrill.play() //Musik anschalten
                localStorage.setItem('flag', "true");
            }
        } */
 
        //Erster Pfeil nach links entfernen
        if (this.graphCount == 0) {
            this.backwardEllipse.setVisible(false)
            this.backwardGraphic.setVisible(false)
            this.downloadButton.setVisible(true)
        } else {
            this.backwardEllipse.setVisible(true)
            this.backwardGraphic.setVisible(true)
            this.downloadButton.setVisible(false)
        }
 
    }
 
}