import Phaser from 'phaser'
import confetti from 'canvas-confetti';//für Konfettiregen bei Öffnen der Seite
 
export default class FinalEnd extends Phaser.Scene {
  private EscButton!: Phaser.GameObjects.Rectangle;
  private returnKey!: Phaser.Input.Keyboard.Key;
 
 
  //Definition der Variablen für das Infofeld unter dem Button 'Weitere Infos'
  private infoMessageBox!: Phaser.GameObjects.Rectangle;
  private infoText!: Phaser.GameObjects.Text;
  private infoHeader!: Phaser.GameObjects.Text;
  private okButton!: Phaser.GameObjects.Text;
  private isInfoVisible: boolean = false;
 
 
  private score!: number;
 
 
  constructor() {
    super('finalend');
  }
 
 
 
  create() {
 
    //Hintergrundbild von Rechenzentrum wird eingefügt
    //this.add.image(400, 300, 'rechenzentrum').setOrigin(0.5, 0.5);
    const bg = this.add.image(0, 0, 'rechenzentrum').setOrigin(0, 0);
    bg.setDisplaySize(this.scale.width, this.scale.height); // Skaliert auf die gesamte Szene
 
    //Konfettiregen
    confetti({
      particleCount: 400,//Anzahl der Konfetti, die fliegen
      spread: 180,//Menge, der Streuung an Konfettis
      startVelocity: 30,//Geschwindigkeit der Konfetti
      origin: { x: 0.5, y: 0 },//Startposition (von oben)
    });
 
 
    const fontSize = Math.min(this.scale.width, this.scale.height) / 18;
   
    const centerX = this.scale.width / 2; // Horizontale Mitte der Szene
    let currentY = this.scale.height / 2 - 100; // Startpunkt für die Texte, etwas über der Mitte
 
 
    //Einfügen der Texte, Texte werden dynamisch in der Mitte der Seite platziert
    this.add.text(centerX, currentY, 'Mission Accomplished!', {
      fontSize: `${fontSize}px`,
      fontStyle: 'bold'
    }).setOrigin(0.5, 0.5); //Text ist mittig platziert
    currentY += 70; //Abstand zum folgenden Text
 
    //Anzeigen des Spielernames
    const spielername = localStorage.getItem('player') || 'Anonym'; //wird kein Name zu Beginn eingegeben, wird der Name Anonym angezeigt
    const scoreTime = localStorage.getItem('stoppedTime');
    const points = localStorage.getItem('score');
    const text = `Herzlichen Glückwunsch, ${spielername}!\n` ;
    const text2 = `Deine Zeit ist ${scoreTime} Minuten!\n` ;
    const text3 = `Du hast ${points} Strafpunkte.`;
 
    //Texte werden dynamisch auf der Seitenmitte angeordnet
    this.add.text(centerX, currentY, text, {
      fontSize: `${fontSize * 0.8}px`,
      fontStyle: 'bold'
    }).setOrigin(0.5, 0.5);
    currentY += 50;
 
    this.add.text(centerX, currentY, text2, {
      fontSize: `${fontSize * 0.8}px`,
      fontStyle: 'bold'
    }).setOrigin(0.5, 0.5);
    currentY += 50;
 
    this.add.text(centerX, currentY, text3, {
      fontSize: `${fontSize * 0.8}px`,
      fontStyle: 'bold'
    }).setOrigin(0.5, 0.5);
    currentY += 50;
 
    //weiterer Text, als Konstante bestimmt, da hiernach später der Infobutton ausgerichtet wird
    const textwillkommen = this.add.text(centerX, currentY, 'Willkommen im Rechenzentrum von FakeBook!', {
      fontSize: `${fontSize * 0.8}px`,
      fontStyle: 'bold'
    }).setOrigin(0.5, 0.5);
 
 
    //weiterer Text
    //this.add.text(50, 550, 'Music by Ross Bugden https://www.youtube.com/c/RossBugden', {
    //    fontSize: '12px'
    //})
 
    // Zur Startseite Button
    const buttonWidth = this.scale.width / 4.5;
    const buttonHeight = this.scale.height / 15;
 
    const padding = 20;
 
    const buttonXX = this.scale.width - padding - buttonWidth / 2;
    const buttonYY = this.scale.height - padding - buttonHeight / 2;
 
    const startButton = this.add.rectangle(
      buttonXX,
      buttonYY,
      buttonWidth,
      buttonHeight,
      0x333333
    )
      .setInteractive()
      .setStrokeStyle(2, 0xffffff)
      .on('pointerover', () => startButton.setFillStyle(0x555555))
      .on('pointerout', () => startButton.setFillStyle(0x333333))
      .on('pointerdown', () => {
        this.scene.stop()
        this.scene.start('credits')
      });
 
    // Text für den Button
    this.add.text(
      buttonXX,
      buttonYY,
      'Spiel beenden',
      {
        fontSize: `${fontSize* 0.8}px`,
        color: '#ffffff',
      }
    ).setOrigin(0.5);
 
    // Weitere Infos Button
    const infoButtonY = textwillkommen.y + textwillkommen.height + 50;
 
    const infoButton = this.add.rectangle(
      centerX,
      infoButtonY,
      buttonWidth,
      buttonHeight,
      0x333333
    )
      .setInteractive()
      .setStrokeStyle(2, 0xffffff)
      .on('pointerover', () => infoButton.setFillStyle(0x555555))
      .on('pointerout', () => infoButton.setFillStyle(0x333333))
      .on('pointerdown', () => this.toggleInfoMessage());
 
    // Text für den Button
    this.add.text(
      centerX,
      infoButtonY,
      'Weitere Infos',
      {
        fontSize: `${fontSize * 0.8}px`,
        color: '#ffffff',
      }
    ).setOrigin(0.5);
 
 
    //Funktion die das Nachrichtenfeld anzeigt bei Klick auf den Button
    this.toggleInfoMessage = () => {
      if (this.isInfoVisible) {
        this.infoMessageBox.setVisible(false);
        this.infoHeader.setVisible(false);
        this.infoText.setVisible(false);
        this.okButton.setVisible(false);
        this.isInfoVisible = false;
      } else {
        this.createInfoMessage();
        this.isInfoVisible = true;
      }
    };
 
    //Nachrichtenfeld wird erstellt
    this.createInfoMessage = () => {
      this.infoMessageBox = this.add.rectangle(
        this.scale.width / 2,
        this.scale.height / 2,
        600,
        300,
        0xFFFFFF
      )
        .setStrokeStyle(2, 0x000000)
        .setOrigin(0.5, 0.5);
 
      //Text im Nachrichtenfeld
      this.infoHeader = this.add.text(
        this.scale.width / 2,
        this.scale.height / 2 - 100,
        'Was ist ein Rechenzentrum?\n',
        {
          fontSize: `${fontSize}px`,
          fontStyle: 'bold',
          color: '#000000',
          align: 'center'
        }
      ).setOrigin(0.5, 0.5);
 
      this.infoText = this.add.text(
        this.scale.width / 2,
        this.scale.height / 2, // Text erscheint mittig im Feld
        '- Ein Gebäude, in dem viele Computer und Server arbeiten.\n' +
        '- Es speichert und verarbeitet große Mengen an Daten, z.B. von Webseiten oder Apps.\n' +
        '- Daten werden verschlüsselt, damit niemand Unbefugtes sie lesen kann.',
        {
            fontSize: `${fontSize * 0.6}px`,
            color: '#009900',
            align: 'center',
            wordWrap: { width: 550, useAdvancedWrap: true },
            lineSpacing: 8 // Weniger Abstand für besseres Layout
        }
    ).setOrigin(0.5, 0.5);
   
 
      //Button im Nachrichtenfeld
      this.okButton = this.add.text(
        this.scale.width / 2,
        this.scale.height / 2 + 130,
        'OK',
        {
          fontSize: `${fontSize}px`,
          fontStyle: 'bold',
          color: '#000000'
        }
      ).setOrigin(0.5, 0.5).setInteractive();
 
      //Nachrichtenfeld schließt sich bei Klick des Ok-Buttons
      this.okButton.on('pointerdown', () => {
        this.toggleInfoMessage();
      });
    };
  }
 
  createInfoMessage() {
    throw new Error('Method not implemented.');
  }
  toggleInfoMessage() {
    throw new Error('Method not implemented.');
  }
}