import Phaser from 'phaser';
import { createThiefAnimations } from "../../animations/ThiefAnimation";

export default class CharacterPreviewNon extends Phaser.Scene {
    private backButton!: Phaser.GameObjects.Rectangle;
    private saveButton!: Phaser.GameObjects.Rectangle;

    constructor() {
        super('characterpreviewnon');
    }

    create() {
        // Hintergrundfarbe
        this.cameras.main.setBackgroundColor('#000000');

        // Dynamische Skalierung der Schriftgröße
        const fontSize = Math.min(this.scale.width, this.scale.height) / 18;

        // Nachricht an Spieler
        this.add.text(
            this.scale.width / 2,
            this.scale.height / 6,
            'Dein Spezialist: ShadowNova',
            {
                fontSize: `${fontSize}px`,
                color: '#ffffff',
                align: 'center',
                wordWrap: { width: this.scale.width * 0.8, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Vorstellungstext
        const introTextY = this.scale.height / 6 + fontSize * 2.0;
        this.add.text(
            this.scale.width / 2,
            introTextY,
            'Mit meiner Kombination aus Strategie und geschicktem Einsatz von Fähigkeiten bleibt kein Ziel außer Reichweite und kein Gegner unvorbereitet.',
            {
                fontSize: `${fontSize * 0.6}px`,
                color: '#ffffff',
                align: 'center', // Text linksbündig
                wordWrap: { width: this.scale.width * 0.6, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Animationen für Dieb erstellen
        createThiefAnimations(this.anims);

        // Balken für Eigenschaften - siehe CharacterPreviewMale
        const barsData = [
            { label: 'Selbstbewusstsein', value: 70 },
            { label: 'Kreativität', value: 60 },
            { label: 'Teamgeist', value: 85 }
        ];

        const barWidth = this.scale.width / 3; 
        const barHeight = 30; 
        const barX = this.scale.width / 3 + 100;
        let barY = introTextY + fontSize *2; 

        const labelPadding = 10;
        const barPadding = 70; 

        barsData.forEach(data => {
            this.add.rectangle(barX, barY + 20, barWidth, barHeight, 0x98fb98) 
                .setOrigin(0, 0)
                .setAlpha(0.5); 

            this.add.text(barX, barY - labelPadding, data.label, {
                fontSize: `${fontSize * 0.6}px`,
                color: '#ffffff',
                align: 'left'
            });

            const progressBar = this.add.rectangle(barX, barY + 20, 0, barHeight, 0x6c63ff)
                .setOrigin(0, 0);

            this.tweens.add({
                targets: progressBar,
                width: barWidth * (data.value / 100), 
                duration: 1000, 
                ease: 'Power2', 
            });

            barY += barPadding; 
        });
        // Position the Thief (aligned with the last bar)
        const lastBarBottom = barY - barPadding + 20 + barHeight; // Unterkante des letzten Balkens
        const thiefScale = 3.5;
        const thiefHeight = 32 * thiefScale; // Höhe des Thief-Sprites (32 ist die Standardgröße)
        const thiefOffsetY = -20; // Negativer Offset, um den Thief leicht nach oben zu verschieben
        const thiefY = lastBarBottom - thiefHeight / 2 + thiefOffsetY; // Zusätzlicher Offset für die Feinanpassung


        // Positionen für Dieb
        const middleY = this.scale.height / 2;

        // Non-binary Dieb (läuft)
        const nonBinaryKeys = ['thief_non-run-down', 'thief_non-run-side-left', 'thief_non-run-up', 'thief_non-run-side'];
        let nonBinaryKeyIndex = 0;

        const nonBinaryThief = this.add.sprite(this.scale.width / 3, thiefY, 'thief_non')
            .setScale(thiefScale)
            .play({
                key: nonBinaryKeys[nonBinaryKeyIndex], // Erste Animation abspielen
                frameRate: 6 // Geschwindigkeit
            });

        // Zeitgesteuerter Wechsel der Animationen für den non-binary Dieb
        this.time.addEvent({
            delay: 1000, // Zeit in Millisekunden zwischen Animationen
            loop: true, // Dauerschleife
            callback: () => {
                nonBinaryKeyIndex = (nonBinaryKeyIndex + 1) % nonBinaryKeys.length; // Nächster Key (zirkulär)
                nonBinaryThief.play({
                    key: nonBinaryKeys[nonBinaryKeyIndex], // Animation ändern
                    frameRate: 6 // Geschwindigkeit (frameRate) angeben
                });
            },
        });

        // Position der Buttons
        const buttonWidth = this.scale.width / 4.5;
        const buttonHeight = this.scale.height / 15;

        // Abstand vom Rand der Buttons
        const padding = 20;

        // Zurück-Button links unten, etwas weiter vom Rand
        this.backButton = this.add.rectangle(
            padding + buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            padding + buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            'Zurück', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Speichern-Button rechts unten, etwas weiter vom Rand
        this.saveButton = this.add.rectangle(
            this.scale.width - padding - buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            this.scale.width - padding - buttonWidth / 2,
            this.scale.height - padding - buttonHeight / 2,
            'Speichern', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Hover-Effekte für Buttons
        const hoverEffect = (button: Phaser.GameObjects.Rectangle) => {
            button.on('pointerover', () => button.setFillStyle(0x555555));
            button.on('pointerout', () => button.setFillStyle(0x333333));
        };

        hoverEffect(this.backButton);
        hoverEffect(this.saveButton);

        // Button-Interaktionen
        this.backButton.on('pointerdown', () => {
            this.scene.stop();
            this.scene.start('characterselection'); 
        });

        this.saveButton.on('pointerdown', () => {
            localStorage.setItem('selectedCharacterGender', 'nonbinary');
            this.scene.stop();
            this.scene.start('playername');
        });
    }
}
