import Phaser from 'phaser';
import { createThreeThiefsAnimations } from '../../animations/ThreeThiefsAnimation';

export default class CharacterSelection extends Phaser.Scene {
    private character1Button!: Phaser.GameObjects.Rectangle;
    private character2Button!: Phaser.GameObjects.Rectangle;
    private character3Button!: Phaser.GameObjects.Rectangle;

    constructor() {
        super('characterselection');
    }

    create() {
        // Hintergrundfarbe setzen
        this.cameras.main.setBackgroundColor('#000000');

        // Animationen erstellen
        createThreeThiefsAnimations(this.anims, 'female'); // weibliche Figur
        createThreeThiefsAnimations(this.anims, 'male'); // männliche Figur
        createThreeThiefsAnimations(this.anims, 'non');  // thief_non (nonbinary) Figur

        // Dynamische Skalierung der Schriftgröße
        const fontSize = Math.min(this.scale.width, this.scale.height) / 18;

        // Nachricht an Spieler
        this.add.text(
            this.scale.width / 2,
            this.scale.height / 4,
            'DATEN SIND MACHT\nWähle deinen Spezialisten!',
            {
                fontSize: `${fontSize}px`,
                color: '#ffffff',
                align: 'center',
                wordWrap: { width: this.scale.width * 0.8, useAdvancedWrap: true },
            }
        ).setOrigin(0.5);

        // Positionen für Thieves
        const middleY = this.scale.height / 2;
        const thiefScale = 3;

        // Weiblicher Thief
        const femaleThief = this.add.sprite(this.scale.width / 4, middleY, 'thief')
            .setScale(thiefScale)
            .play('thief-idle-down');

        // Männlicher Thief
        const maleThief = this.add.sprite(this.scale.width / 2, middleY, 'thief_male')
            .setScale(thiefScale)
            .play('thief_male-idle-down');

        // Thief_non (nonbinary)
        const nonThief = this.add.sprite(this.scale.width * 3 / 4, middleY, 'thief_non')
            .setScale(thiefScale)
            .play('thief_non-idle-down');

        // alle Diebe auf die gleiche Höhe
        femaleThief.setY(middleY);
        maleThief.setY(middleY);
        nonThief.setY(middleY);

        // Buttons dynamisch anpassen
        const buttonWidth = this.scale.width / 4.5;
        const buttonHeight = this.scale.height / 15;
        const buttonOffsetY = 80; // Abstand zwischen Dieben und Buttons

        // Position der Buttons
        const buttonY = middleY + Math.max(femaleThief.displayHeight, maleThief.displayHeight, nonThief.displayHeight) / 2 + buttonOffsetY;

        // Button für weiblichen Thief
        this.character1Button = this.add.rectangle(
            this.scale.width / 4,
            buttonY,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            this.scale.width / 4,
            buttonY,
            'LunaShade', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Button für männlichen Thief
        this.character2Button = this.add.rectangle(
            this.scale.width / 2,
            buttonY,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            this.scale.width / 2,
            buttonY,
            'IronVortex', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Button für thief_nonbinary
        this.character3Button = this.add.rectangle(
            this.scale.width * 3 / 4,
            buttonY,
            buttonWidth,
            buttonHeight,
            0x333333
        )
        .setInteractive()
        .setStrokeStyle(2, 0xffffff);

        this.add.text(
            this.scale.width * 3 / 4,
            buttonY,
            'ShadowNova', 
            {
                fontSize: `${fontSize * 0.8}px`,
                color: '#ffffff',
            }
        ).setOrigin(0.5);

        // Hover-Effekt für Buttons
        const hoverEffect = (button: Phaser.GameObjects.Rectangle) => {
            button.on('pointerover', () => button.setFillStyle(0x555555));
            button.on('pointerout', () => button.setFillStyle(0x333333));
        };

        hoverEffect(this.character1Button);
        hoverEffect(this.character2Button);
        hoverEffect(this.character3Button);

        // Button-Interaktionen
        this.character1Button.on('pointerdown', () => {
            localStorage.setItem('selectedCharacterGender', 'female');
            this.scene.stop();
            this.scene.start('characterpreviewfemale');
        });

        this.character2Button.on('pointerdown', () => {
            localStorage.setItem('selectedCharacterGender', 'male');
            this.scene.stop();
            this.scene.start('characterpreviewmale');
        });

        this.character3Button.on('pointerdown', () => {
            localStorage.setItem('selectedCharacterGender', 'nonbinary');
            this.scene.stop();
            this.scene.start('characterpreviewnon');
        });
    }
}
